import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  VirtualAssistantDoctorResultDTO,
  VirtualAssistantDoctorsDTO,
  VirtualAssistantDoctorTagsDTO,
} from '../../DTOS/VA_Configurations/VA_Configurations';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-va-doctors',
  templateUrl: './va-doctors.component.html',
  styleUrls: ['./va-doctors.component.scss'],
})
export class VaDoctorsComponent implements AfterViewInit, OnChanges {
  // [x: string]: any;

  public User: User;
  slidePanelOpen: Boolean = false;
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  sidepanelItems: VirtualAssistantDoctorsDTO = {} as any;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public accountID: number;
  @Input() public isStandalone: boolean;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  doctor: VirtualAssistantDoctorResultDTO;
  listItems: VirtualAssistantDoctorsDTO[];
  from: Date = new Date();
  to: Date = new Date();
  searchTearm = '';
  public dataSource: any;
  selectable = true;
  removable = true;
  addOnBlur = true;
  _dataLoading = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  listDoctorTag: VirtualAssistantDoctorTagsDTO[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    'doctorID',
    'doctorName',
    'createDate',
    'tags',
    'Actions',
  ];
  isEditing: boolean;
  showForm: boolean;
  account: any;
  backup_account: any;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadLists();
  }

  ngOnChanges(): void {
    this.User = this.authenticationService.currentUserValue;
    this.LoadLists();
  }

  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidepanelItems = {} as any;
  }
  public openEditSidePanel(item) {
    this.slidePanelOpen = true;
    this.sidepanelItems = _.cloneDeep(item);
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelItems = {} as any;
    this.LoadLists();
  }

  public LoadLists() {
    this.isSubmitting = true;
    this.isLoading = true;
    const accountId = this.accountID;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;

    this.API.LoadListDoctorVirtualAssistant(
      accountId,
      this.currentPage,
      this.pageSize,
      this.searchTearm
    ).subscribe(
      (result) => {
        const res = result as VirtualAssistantDoctorResultDTO;
        this.listItems = res.items;
        for (let i = 0; i < this.listItems.length; i++) {
          this.listItems[i].createDateView = moment(
            this.listItems[i].createDate
          ).format('DD MMMM [del] YYYY');
        }
        this.dataSource = new MatTableDataSource<VirtualAssistantDoctorsDTO>(
          this.listItems
        );
        this.dataSource.paginator = this.paginator;
        this.doctor = res;
        this.totalSize = this.doctor.totalCount;
        this.pageSize = this.doctor.pageSize;
        this.currentPage = this.doctor.pageIndex;
        this.isSubmitting = false;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public Add() {
    this.isSubmitting = true;
    this.sidepanelItems.accountID = this.accountID;
    this.API.CreateDoctorVirtualAssistant(
      this.sidepanelItems.accountID,
      this.sidepanelItems
    ).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(`¡Listo!, la Doctor creado correctamente. `, 'OK ', {
        duration: 70000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      });
    });
  }

  public Update() {
    this.isSubmitting = true;

    this.API.UpdateDoctorVirtualAssistant(
      this.sidepanelItems.accountID,
      this.sidepanelItems
    ).subscribe(() => {
      this.LoadLists();
      this.isSubmitting = false;
      this.closeAddNewSidePanel();
      this._snackBar.open(
        `¡Listo!, la Doctor actualizado correctamente. `,
        'OK ',
        {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        }
      );
    });
  }

  public Delete(event: Event, doctor: VirtualAssistantDoctorsDTO) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const accountID = this.accountID;
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteDoctorVirtualAssistant(
          accountID,
          doctor.doctorID
        ).subscribe((r) => {
          this.LoadLists();
        }),
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadLists();
  }

  public clearFilter() {
    this.searchTearm = '';
    this.from = new Date();
    this.to = new Date();
  }

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.showForm = false;
    this.account = _.cloneDeep(this.backup_account);
    this.backup_account = {} as any;
    this.LoadLists();
  };
}
