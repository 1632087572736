import * as _ from 'lodash';
import * as moment from 'moment';

import { AfterViewInit, Input, ViewChild } from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import {
  IncomingCall,
  IncomingCallsResultDTO,
} from '../../DTOS/IncomingCall/IncomingCallsDTO';
import {
  ReportsViewerComponent,
  reportsViewerData,
} from 'src/app/_dialogs/reports-viewer/reports-viewer.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { ApiService } from '../../api.service';
import { AuthenticationService } from '../../_services';
import { Component } from '@angular/core';
import { Howl } from 'howler';
import { IAppChatLinkedAppointment } from '../chat/chat.component';
import { IntentType } from 'src/app/DTOS/VA_Configurations/VA_Configurations';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { User } from '../../_models/user';
import { formatPhoneNumber } from 'src/app/Helpers/Formatters';

@Component({
  selector: 'app-missed-calls',
  templateUrl: './missed-calls.component.html',
  styleUrls: ['./missed-calls.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MissedCallsComponent implements AfterViewInit {
  User: User;
  isLoading = false;
  isPlayingAudio = false;
  searchTearm = '';
  isLoadingPermissions = true;
  hasVA_Permission = false;
  hasTwoWayMessage = false;

  expandedElement: IncomingCall | null;

  public dataSource: MatTableDataSource<IncomingCall>;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  selectedIntentTypes: number[] = [];
  IncomingCalls: IncomingCallsResultDTO;
  listItems: IncomingCall[];
  from: Date = moment().startOf('month').toDate();
  to: Date = new Date();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  IntentTypes: IntentType[];
  checkedCalls: number[] = [];
  clinicId: number;
  chatAppointment: IAppChatLinkedAppointment = null;
  chatIsLoading: boolean;
  isCheckingForNewMessages: boolean;
  longPollingTimeout: any;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    private _snackBar: MatSnackBar
  ) {}

  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadMissedCalls();
    this.LoadIntentTypes();
  }

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
      this.LoadMissedCalls();
      this.LoadIntentTypes();
      this.permissionSlugsService.fetch();

      this.permissionSlugsService.permissionsListener.subscribe((r) => {
        this.hasVA_Permission = r.includes(PermissionSlugs.VIRTUAL_ASSISTANT);
      });

      this.permissionSlugsService.loaderListener.subscribe((r) => {
        this.isLoadingPermissions = r;
      });

      this.API.HasTwoWayTextingEnabled(this.User.clinicId).subscribe(
        (r: { hasTwoWayTextingEnabled: boolean }) => {
          this.hasTwoWayMessage = r.hasTwoWayTextingEnabled;
        }
      );
    });

    if (!Notification) {
      alert(
        'Las notificaciones de escritorio no están disponibles en su navegador. Prueba Google Chrome.'
      );
      return;
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }
  public baseUrl: string = '';

  DownloadAppointmentsReport(ExportType: string) {
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    let link =
      this.baseUrl +
      `Reports/VaCallsReport?StartDate=${moment(this.from).format(
        'YYYY-MM-DD'
      )}&EndDate=${moment(this.to).format(
        'YYYY-MM-DD'
      )}&ExportType=${ExportType}&clinicId=${this.clinicId}`;

    if (ExportType === 'PDF') {
      let data: reportsViewerData = {
        link: link,
      };
      this.dialog
        .open(ReportsViewerComponent, {
          data: data,
          width: '80%',
          disableClose: true,
        })
        .afterClosed();
    } else {
      window.open(link, '_blank');
    }
  }
  private checkForNewMessages = () => {
    this.isCheckingForNewMessages = true;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const toFormat = moment(this.to).format('YYYY-MM-DD');
    const fromFormat = moment(this.from).format('YYYY-MM-DD');
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetMissedCall(
      clinicID,
      this.currentPage,
      this.pageSize,
      toFormat,
      fromFormat,
      this.searchTearm,
      this.selectedIntentTypes
    ).subscribe((result) => {
      const res = result as IncomingCallsResultDTO;
      const itemsWithUnreadMessages = res.items.filter(
        (r) => r.unreadSmsResponsesCount
      );
      if (itemsWithUnreadMessages.length) {
        this.notifyNewMessages();
        itemsWithUnreadMessages.forEach((call) => {
          const missedCallInDatasource = this.dataSource.data.find(
            (datasourceCall) => datasourceCall.callID === call.callID
          );
          if (missedCallInDatasource) {
            missedCallInDatasource.unreadSmsResponsesCount =
              call.unreadSmsResponsesCount;
          }
        });
      }
      this.isCheckingForNewMessages = false;
      this.setLongPolling();
    });
  };
  public setLongPolling() {
    if (this.longPollingTimeout) {
      clearTimeout(this.longPollingTimeout);
    }
    if (this.dataSource.data.some((d) => d.appointmentId)) {
      this.longPollingTimeout = setTimeout(() => {
        this.checkForNewMessages();
      }, 60 * 1000);
    }
  }
  public LoadIntentTypes() {
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.API.GetIntentTypes(clinicID).subscribe((re) => {
      const result = re as IntentType[];
      this.IntentTypes = result;
    });
  }

  public async LoadMissedCalls() {
    this.isLoading = true;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const toFormat = moment(this.to).format('YYYY-MM-DD');
    const fromFormat = moment(this.from).format('YYYY-MM-DD');
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.checkedCalls = [];

    this.API.GetMissedCall(
      clinicID,
      this.currentPage,
      this.pageSize,
      toFormat,
      fromFormat,
      this.searchTearm,
      this.selectedIntentTypes
    ).subscribe(
      (result) => {
        const res = result as IncomingCallsResultDTO;
        res.items.forEach((i) => {
          i.fromPhoneNumber = formatPhoneNumber(i.fromPhoneNumber);

          const dLocal = moment.utc(i.createDate).local();

          i._display_createDate = dLocal.format('DD MMM [,] YYYY');
          i._display_createTime = dLocal.format('hh:mm A');

          if (i.appointmentRequests?.length) {
            i.callerName = i.appointmentRequests[0].patientName;
          }

          if (i.callDurationInSeconds < 60) {
            i._callDurationInMinutes = 1;
          } else if (i.callDurationInSeconds > 60) {
            i._callDurationInMinutes = Math.ceil(i.callDurationInSeconds / 60);
          } else if (!i.callDurationInSeconds) {
            i._callDurationInMinutes = 0;
          }

          const itentsSet = [
            ...new Set(i.petitions.map((p) => p.intentObj.intentID)),
          ].map(
            (intentId) =>
              i.petitions.find((p) => p.intentObj.intentID === intentId)
                .intentObj
          );

          i.distinctIntents = itentsSet;
        });

        const itemsWithUnreadMessages = res.items.filter(
          (r) => r.unreadSmsResponsesCount
        );
        const itemsWithoutUnreadMessages = res.items.filter(
          (r) => !r.unreadSmsResponsesCount
        );
        const items = [
          ...itemsWithUnreadMessages,
          ...itemsWithoutUnreadMessages,
        ];

        this.dataSource = new MatTableDataSource<IncomingCall>(items);
        this.IncomingCalls = res;
        this.totalSize = this.IncomingCalls.totalCount;
        this.pageSize = this.IncomingCalls.pageSize;
        this.currentPage = this.IncomingCalls.pageIndex;
        this.listItems = this.IncomingCalls.items;
        this.isLoading = false;

        if (itemsWithUnreadMessages.length) {
          this.notifyNewMessages();
        }
        if (this.longPollingTimeout) {
          clearTimeout(this.longPollingTimeout);
        }
        this.setLongPolling();
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  public hasNotificationPermissions = () =>
    Notification.permission === 'granted';
  public requestNotificationsPermission = () =>
    Notification.requestPermission();
  notifyNewMessages() {
    const howl = new Howl({
      src: '/audio/sms-notification-sound.mp3',
    });
    howl.play();

    const notificationText = `Notificacion: Tienes nuevos mensajes de texto `;
    this._snackBar.open(notificationText, 'OK ', {
      duration: 70000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: 'success-dialog',
    });
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    } else {
      const notification = new Notification(notificationText, {
        icon: '/images/logo_footer.png',
        body: 'Haz recibido nuevos mensajes de texto para una llamada perdida en el asistente virtual.',
      });
      notification.onclick = function () {
        window.open('https://www.citamed-secure.com/llamadasPerdidas');
      };
    }
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadMissedCalls();
  }
  public onChatButtonClicked(element: IncomingCall) {
    this.chatIsLoading = true;
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    if (element) {
      element.unreadSmsResponsesCount = 0;
    }
    if (this.hasTwoWayMessage) {
      if (element._AppChatLinkedAppointment) {
        this.chatAppointment = _.cloneDeep(element._AppChatLinkedAppointment);
        this.chatIsLoading = false;
      } else {
        this.API.VA_GetAssociatedAppointment(
          element.callID,
          clinicID
        ).subscribe(
          (result: { appointmentId: number; patientPhoneNumber: string }) => {
            this.chatAppointment = {
              appointmentId: result.appointmentId,
              patientPhoneNumber: result.patientPhoneNumber,
            };
            element._AppChatLinkedAppointment = _.cloneDeep({
              appointmentId: result.appointmentId,
              patientPhoneNumber: result.patientPhoneNumber,
            });
            const datasourceElement = this.dataSource.data.find(
              (c) => c.callID === element.callID
            );
            if (datasourceElement) {
              datasourceElement.appointmentId = result.appointmentId;
              if (!this.longPollingTimeout) {
                this.setLongPolling();
              }
            }
            this.chatIsLoading = false;
          }
        );
      }
    }
  }
  public clearFilter() {
    this.searchTearm = '';
    this.from = moment().startOf('month').toDate();
    this.to = new Date();
    this.selectedIntentTypes = [];
    this.checkedCalls = [];
    this.unselectAll();
  }

  public GetSelectedCalls() {
    return this.listItems.filter((a) => a.checked).length;
  }

  public unselectAll() {
    for (const i of this.listItems) {
      i.checked = false;
    }
    this.checkedCalls = [];
  }

  onCheckboxChange(e, element) {
    if (e.checked) {
      this.checkedCalls.push(element.callID);
      element.checked = true;
    } else {
      element.checked = false;
      const index: number = this.checkedCalls.indexOf(element.callID);
      this.checkedCalls.splice(index, 1);
    }
  }

  deleteCalls() {
    const selected = this.GetSelectedCalls();
    const message = `¿Estas seguro de querer eliminar ${selected} llamadas?`;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;
        this.API.DeleteMissedCall(this.clinicId, this.checkedCalls).subscribe(
          () => {
            this.isLoading = false;
            this.LoadMissedCalls();
          },
          (error) => {
            this.showError(error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  getDisplayedColumns() {
    const columns = [
      'selectCalls',
      'callerName',
      'fromPhoneNumber',
      'geoReferencing',
      'callDurationInSeconds',
      'petitions',
      'createDate',
      this.hasTwoWayMessage ? 'chat' : null,
    ].filter((e) => e);

    return columns;
  }
}
