import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { ApiService } from '../api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../dtos/AdminViewAsClinicDTO';
import { UserEditDTO, UserGetDTO } from '../dtos/Users/Users';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../loading-dialog/loading-dialog.component';
import { AddUserComponent } from '../add-user/add-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements AfterViewInit, OnChanges {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService
  ) {}

  httpClient: HttpClient;
  baseUrl: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public userToEdit = new UserEditDTO();
  public userToCreate = new UserEditDTO();
  displayedColumns: string[] = ['Nombre', 'Email', 'NombreUsuario', 'Actions'];
  @Input() public isStandalone: boolean;
  public isUpdatingUser = false;
  public User: User;
  public editSidePanelOpen = false;
  @Input() public createSidePanelOpen = false;
  minSelectedDate: Date = new Date();
  public users: UserGetDTO[];
  dataSource: MatTableDataSource<UserGetDTO>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public ViewAs: AdminViewAsClinicDTO;

  ngOnChanges(changes: SimpleChanges): void {
    this.LoadUsers();
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadUsers();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadUsers();
  }

  public LoadUsers() {
    try {
      if (this.isStandalone && !this.ViewAs?.clinicId) {
        return;
      }

      const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
      this.ShowLoading();

      this.API.GetAllUsers(clinicId).subscribe(
        (result) => {
          const res = _.orderBy(result as UserGetDTO[], 'isActive', 'desc');

          if (res.length < 10) {
            _.range(10 - res.length).forEach(() => {
              res.push({} as any);
            });
          }
          this.users = res;

          this.hideLoading();
        },
        (error) => {
          console.error(error);
          this.hideLoading();
          this.showError(error);
        }
      );
    } catch (e) {
      this.hideLoading();
    }
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  public openUserCreatePanel() {
    const dto: UserEditDTO = new UserEditDTO();
    dto.isActive = true;
    this.userToCreate = dto;
    this.createSidePanelOpen = true;
  }

  public openUserEditPanel(user: UserGetDTO) {
    const dto: UserEditDTO = new UserEditDTO();
    dto.emailAddress = user.emailAddress;
    dto.loginname = user.loginname;
    dto.username = user.username;
    dto.isActive = user.isActive;
    dto.userID = user.userId;
    dto.clinicID = user.clinicId;
    dto.mustResetPassword = user.mustResetPassword;
    this.userToEdit = dto;
    this.editSidePanelOpen = true;
    dto.mustResetPassword = user.mustResetPassword;
  }

  public closeUserEditPanel() {
    this.userToEdit = new UserEditDTO();
    this.editSidePanelOpen = false;
  }

  public closeUserCreatePanel(event) {
    this.userToEdit = new UserEditDTO();
    this.createSidePanelOpen = event;
  }

  public isSaveChangesDisabled() {
    if (this.isUpdatingUser) {
      return true;
    }
    if (!this.userToEdit.newPassword) {
      return false;
    } else if (
      this.userToEdit.newPassword !== this.userToEdit.newPasswordRepeat
    ) {
      return true;
    }
  }
  public doUpdate(user: UserEditDTO, event) {
    if (user.newPassword && user.newPassword !== user.newPasswordRepeat) {
      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás seguro/a de querer actualizar este usuario?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        // confirm action
        this.isUpdatingUser = true;
        this.API.EditUser(user).subscribe(
          () => {
            this.LoadUsers();
            this.closeUserEditPanel();
            this.isUpdatingUser = false;
          },
          () => {
            alert('Ha ocurrido un error, por favor intentalo una vez más.');
            this.LoadUsers();
            this.isUpdatingUser = false;
          }
        );
      },
      reject: () => {
        // reject action
      },
    });
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
}
