import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Howl } from 'howler';
import { ApiService } from 'src/app/api.service';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { CallStatusGET_DTO } from '../../dtos/CallStatus/DTOS';
import { ReminderSequenceCreationDTO } from '../../DTOS/Reminders/ReminderSequence';
import { ReminderSetDto } from '../../dtos/ReminderSets/ReminderSetDto';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';
@Component({
  selector: 'app-add-reminders',
  templateUrl: './add-reminders.component.html',
  styleUrls: ['./add-reminders.component.scss'],
})
/** add-reminders component*/
export class AddRemindersComponent implements AfterViewInit {
  /** add-reminders ctor */
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.ReminderSequences = new Array<ReminderSequenceCreationDTO>();
  }
  ReminderSets: ReminderSetDto[];
  public selectedReminderSetCopy: ReminderSetDto;
  phoneNumber: string;
  public replaceRemset: ReminderSetDto;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  public reminderName: string;
  public isSubmitted = false;
  public reminderType: string;
  current_Audio_Hawl: any;
  isLoading = false;
  isPlayingAudio = false;
  public callStatuses: CallStatusGET_DTO[];
  public ReminderSequences: ReminderSequenceCreationDTO[];
  httpClient: HttpClient;
  public ViewAs: AdminViewAsClinicDTO;
  baseUrl: string;
  public User: User;

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadCallStatuses();
    this.FetchReminderSets();
  }

  public fethClinicPhoneNumber(clinicId: number) {
    this.API.GetClinicPhoneNumber(clinicId).subscribe(
      (r: { phoneNumber: string }) => {
        this.phoneNumber = r.phoneNumber;
      }
    );
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchReminderSets();
    this.fethClinicPhoneNumber(this.ViewAs.clinicId);
  }
  public onCopyFromChange() {
    const confirmed = window.confirm(
      '¿Estás seguro de querer copiar de este mensaje?'
    );
    let confirmedTwo = false;

    if (confirmed) {
      this.reminderName = this.selectedReminderSetCopy.reminderSetName;
      if (this.ReminderSequences && this.ReminderSequences.length > 0) {
        confirmedTwo = window.confirm('¿Mantenemos los registros existentes?');
      }
      this.httpClient
        .get(
          this.baseUrl +
            'ReminderSet/GetReminderSet?ReminderSetId=' +
            this.selectedReminderSetCopy.reminderSetId
        )
        .subscribe((result) => {
          const RemSeqs = result[
            'reminderSequences'
          ] as ReminderSequenceCreationDTO[];
          if (confirmedTwo) {
            this.ReminderSequences = [...this.ReminderSequences, ...RemSeqs];
          } else {
            this.ReminderSequences = RemSeqs;
          }
        });
    }
  }
  public GetSelectedRemSeqs() {
    return this.ReminderSequences.filter((e) => e.checked);
  }
  LoadCallStatuses() {
    this.httpClient
      .get<CallStatusGET_DTO[]>(this.baseUrl + 'CallStatus/Get')
      .subscribe((result) => {
        this.callStatuses = result as CallStatusGET_DTO[];
      });
  }
  public StopAudio() {
    this.current_Audio_Hawl.stop();
    this.isPlayingAudio = false;
  }

  public insertB4(item: ReminderSequenceCreationDTO) {
    const index = this.ReminderSequences.indexOf(item);
    this.ReminderSequences.splice(index, 0, new ReminderSequenceCreationDTO());
  }

  public playText(txt: string) {
    if (txt) {
      this.isPlayingAudio = true;
      const arg = {
        src: this.baseUrl + 'api/speak/' + txt + '.mp3',
        onend: () => {
          this.isPlayingAudio = false;
        },
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  public playAudioFromURL(url: string) {
    if (url) {
      this.isPlayingAudio = true;
      const arg = {
        src: url,
        onend: () => {
          this.isPlayingAudio = false;
        },
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  public removeReminderSeq(remseq: ReminderSequenceCreationDTO) {
    const index = this.ReminderSequences.indexOf(remseq);
    if (index > -1) {
      this.ReminderSequences.splice(index, 1);
    }
  }

  public IsValidURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }
  public SwapReminderSequences() {
    const selectedRemSeqs = this.GetSelectedRemSeqs();
    const A: ReminderSequenceCreationDTO = selectedRemSeqs[0];
    const B: ReminderSequenceCreationDTO = selectedRemSeqs[1];
    const AIndex = this.ReminderSequences.indexOf(A);
    const BIndex = this.ReminderSequences.indexOf(B);
    const Acopy = this.ReminderSequences[AIndex];
    this.ReminderSequences[AIndex] = B;
    this.ReminderSequences[BIndex] = Acopy;
  }

  public addEmptyReminderSequence() {
    this.ReminderSequences.push(new ReminderSequenceCreationDTO());
  }

  public IsFormValid() {
    if (this.isSubmitted) {
      return false;
    }
    return this.reminderName &&
      this.reminderType &&
      this.ReminderSequences.length > 0
      ? true
      : false;
  }
  public SaveReminder() {
    let clinicid = this.User.clinicId;

    if (this.ViewAs) {
      clinicid = this.ViewAs.clinicId;
    }

    const dto = {
      reminderType: this.reminderType,
      reminderName: this.reminderName,
      ReminderSequences: this.ReminderSequences,
      clinicid,
      Replace_ReminderSetID: null,
    };

    if (this.replaceRemset?.reminderSetId) {
      dto.Replace_ReminderSetID = this.replaceRemset?.reminderSetId;
    }

    this.ShowLoading();
    this.httpClient
      .post(this.baseUrl + 'ReminderSet/CreateReminder', dto)
      .subscribe(
        () => {
          this.isSubmitted = true;
          this.hideLoading();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'El mensaje ha sido registrado correctamente.',
              'Okay',
              null
            ),
          });
        },
        (error) => {
          this.hideLoading();
          this.showError(error);
        }
      );
  }

  public FetchReminderSets() {
    this.isLoading = true;

    let route = this.baseUrl + `ReminderSet/SearchReminders`;

    if (this.ViewAs) {
      route = route + '?ClinicID=' + this.ViewAs.clinicId;
    }

    this.httpClient.get<ReminderSetDto[]>(route).subscribe(
      (result) => {
        const res = result as ReminderSetDto[];
        this.ReminderSets = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
    this.isLoading = false;
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
    this.isLoading = true;
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
}
