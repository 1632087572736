import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent,
} from '../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { AfterViewInit, Component } from '@angular/core';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services';
import { ImportAppointmentsDialogComponent } from '../_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import PermissionSlugs from '../Constants/PermissionSlugs';
import { PermissionSlugsService } from '../Services/PermissionSlugsService';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TawkToService } from '../_services/TawkToService';
import { User } from '../_models';
import { defineFullCalendarElement } from '@fullcalendar/web-component';

defineFullCalendarElement();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app-component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tawkToService: TawkToService,
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private permissionSlugsService: PermissionSlugsService,
    private API: ApiService
  ) {
    if (window.sessionStorage['hideSpecialBanner']) {
      this.showBanner = false;
    }
  }
  currentUser: User;

  public showDWL = false;
  public hideAppointmentManagement = false;
  public showVirtualAssistant = false;
  public showCampaigns = false;
  public showTransitionalCare = false;
  public patientManagement = false;
  public isPrinter = false;
  public showBanner = true;
  public hasBookingPermission = false;
  public isCitamedV2 = false;
  isExpanded = false;

  public showV2Banner: boolean = false;
  public getAppLogo() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    if (v2Flag) {
      return '/images/v2-assets/citamed-logo-white.png';
    }
    return '/images/white-logo.png';
  }
  public setV2Theme() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    localStorage.setItem('v2flag', String(!v2Flag));
    if (!v2Flag) {
      this.showV2Banner = true;
      setTimeout(() => {
        this.showV2Banner = false;
      }, 7 * 1000);
    }
  }
  public getRootClass() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    if (v2Flag) {
      return { 'citamed-v2-cyan': true, 'hidden-content': this.showV2Banner };
    }
    return {};
  }

  openLink(link) {
    window.open(link, '_blank');
  }
  ngAfterViewInit() {
    this.SetSticky();
    this.authenticationService.currentUser.subscribe((currentUserValue) => {
      if (!currentUserValue?.clinicId) {
        this.currentUser = null;
        return;
      }
      this.currentUser = currentUserValue;
      if (this.currentUser?.userId) {
        this.permissionSlugsService.fetch();
      }
      this.tawkToService.StartChat(this.currentUser);
      if (this.currentUser.isAdmin) {
        this.isPrinter = this.currentUser.isAdmin;
      } else {
        this.API.IsUserPrinter(this.currentUser.clinicId).subscribe(
          () => {
            this.isPrinter = true;
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (
          confirm(
            'Hay una nueva actualizacion. Acepte para actualizar a citamed'
          )
        ) {
          window.location.reload();
        }
      });
    }

    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.showDWL = r.includes(PermissionSlugs.DIGITAL_WAITING_LIST);
      this.hideAppointmentManagement = r.includes(
        PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
      );
      this.showVirtualAssistant = r.includes(PermissionSlugs.VIRTUAL_ASSISTANT);
      this.showCampaigns = r.includes(PermissionSlugs.CAMPAIGNS);
      this.showTransitionalCare = r.includes(PermissionSlugs.TRANSITIONAL_CARE);
      this.patientManagement = r.includes(PermissionSlugs.PATIENT_MANAGEMENT);
      this.hasBookingPermission = r.includes(PermissionSlugs.BOOKINGS);
    });

    // Transitional Care Management Users
    if (
      this.hideAppointmentManagement === true &&
      this.showTransitionalCare === true
    ) {
      this.router.navigate(['/Transitional-Care-admisions-and-discharges']);
    } else if (
      this.hideAppointmentManagement === true &&
      this.showCampaigns === true
    ) {
      this.router.navigate(['/campañas/crear']);
    } else if (
      this.hideAppointmentManagement === true &&
      this.showVirtualAssistant === true &&
      this.showTransitionalCare === false
    ) {
      this.router.navigate(['/llamadasPerdidas']);
    }
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }
  public hidebanner() {
    window.sessionStorage['hideSpecialBanner'] = true;
    this.showBanner = false;
  }
  private SetSticky() {
    return;
    try {
      window.onscroll = function () {
        myFunction();
      };
      const header = document.getElementById('myHeader');
      const sticky = header.offsetTop;
      function myFunction() {
        try {
          if (window.pageYOffset > sticky) {
            header.classList.add('sticky');
          } else {
            header.classList.remove('sticky');
          }
        } catch (e) {}
      }
    } catch (e) {}
  }

  installPwa(): void {}

  OpenAppointmentAddDialog() {
    const data: AddAppointmentDialogData = {
      clinicID: this.currentUser.clinicId,
      UserId: this.currentUser.userId,
      ClinicName: this.currentUser.clinicName,
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! La cita ha sido añadida correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }
  logout() {
    this.authenticationService.logout();
    this.tawkToService.endChat();
    this.router.navigate(['/login']);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  OpenImportAppointmentDialog() {
    this.dialog
      .open(ImportAppointmentsDialogComponent, {
        width: '50%',
        disableClose: false,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! Las citas han sido importadas correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }
}
