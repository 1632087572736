import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { EHRGetDTO } from 'src/app/DTOS/Ehrs/EhrGetDTO';

@Component({
  selector: 'app-ehrs',
  templateUrl: './ehrs.component.html',
  styleUrls: ['./ehrs.component.scss'],
})
export class EhrsComponent implements OnInit {
  constructor(private API: ApiService) {}
  public EHRsList: EHRGetDTO[];
  ngOnInit() {
    this.loadAll();
  }

  public loadAll() {
    this.API.GetAllEhrs().subscribe((r) => {
      const result = r as EHRGetDTO[];
      this.EHRsList = result;
    });
  }
}
