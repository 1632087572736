import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-selection-ribbon',
  templateUrl: './date-selection-ribbon.component.html',
  styleUrls: ['./date-selection-ribbon.component.scss'],
})
export class DateSelectionRibbonComponent {
  constructor() {}
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() public date: Date = new Date();

  public onNextDateClicked = () => {
    this.AddDays(1);
  };
  public onPrevDayClicked = () => {
    this.AddDays(-1);
  };

  private AddDays(days: number) {
    this.date = moment(this.date).add(days, 'days').toDate();
    this.fireEmit();
  }
  public fireEmit = () => this.dateChange.emit(this.date);
  public formatDate = () => {
    return moment(this.date).format('dddd DD [de] MMMM, YYYY');
  };
}
