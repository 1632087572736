import { Component, OnInit, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { CSVImportDialogComponent } from '../csv-import-dialog/csv-import-dialog';
type ImportSourceSelection = 'PDF' | 'CSV';
type AddAppointmentsStep = 'SourceSelector' | 'Form';

@Component({
  selector: 'app-import-appointments-dialog',
  templateUrl: './import-appointments-dialog.component.html',
  styleUrls: ['./import-appointments-dialog.component.scss'],
})
export class ImportAppointmentsDialogComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}
  ViewAs: AdminViewAsClinicDTO;
  isLoading = false;
  public User: User;
  public source: ImportSourceSelection;
  public step: AddAppointmentsStep = 'SourceSelector';

  @ViewChild('fileImportInput') fileImportInput: any;

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
    });
  }
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  public onStepChanged(newStep: AddAppointmentsStep) {
    if (newStep === 'Form' && this.source === 'CSV') {
      this.dialog.open(CSVImportDialogComponent, {
        maxWidth: '100vw',
        maxHeight: '95vh',
        height: '90%',
        width: '80%',
      });
    } else {
      this.step = newStep;
    }
  }

  public getUrl() {
    const clinicid = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    return `/Printer/FileIn?clinicId=${clinicid}`;
  }

  openDialog(importUrl: string) {
    const dialogRef = this.dialog.open(ImportResultDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '95vh',
      height: '95%',
      width: '100%',
      data: {
        importUrl,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public uploadComplete(event: any) {
    const response = event.originalEvent?.body;
    if (response?.forwardUrl) {
      this.openDialog(response.forwardUrl);
    }
  }
}

@Component({
  selector: 'app-import-result-dialog',
  templateUrl: 'import-result-dialog.html',
})
export class ImportResultDialogComponent {
  constructor(
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.importUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      data.importUrl
    );
  }
  public importUrl: SafeResourceUrl;
}
