import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateSelectArg, FullCalendarComponent } from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import * as introJs from 'intro.js/intro.js';
import * as moment from 'moment';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  IAppointmentListItem,
  HomeViewModel,
  INoCallDate,
  ISummaryCallType,
  system_message,
} from '../../DTOS/Appointments/AppointmentListItem';
import { CallStatusGET_DTO } from '../../dtos/CallStatus/DTOS';
import { Doctor } from '../../dtos/Doctors/DoctorDTO';
import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent,
} from '../../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import {
  AppointmentDetailsDialogComponent,
  AppointmentDetailsDialogData,
  AppointmentDetails,
} from '../../_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import {
  ReportsViewerComponent,
  reportsViewerData,
} from '../../_dialogs/reports-viewer/reports-viewer.component';
import {
  UpdateAppointmentCallStatusDialogComponent,
  UpdateAppointmentCallStatusDialogData,
} from '../../_dialogs/update-appointment-call-status/update-appointment-call-status.component';
import {
  UpdateAppointmentDialogComponent,
  UpdateAppointmentDialogData,
} from '../../_dialogs/update-appointment/update-appointment.component';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { BalanceDueComponent } from '../../_dialogs/balance-due/balance-due.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { ApiService } from 'src/app/api.service';
import { ClinicAppointmentType } from 'src/app/DTOS/Appointments-types/ClinicAppointmentType';
import * as _ from 'lodash';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { ClinicDoctorAssistanceStatusDTO } from 'src/app/DTOS/AssistanceStatus';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterUserViewAsSelectorComponent } from 'src/app/master-user-view-as-selector/master-user-view-as-selector.component';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-au';
import { CalendarOptions } from '@fullcalendar/web-component';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HomeComponent implements AfterContentInit, OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    @Inject('BASE_URL') baseUrl: string,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.baseUrl = baseUrl;
    moment.locale('es');
  }

  public showAppointmentAssistance: boolean = false;

  public hasV2Home() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    return v2Flag;
  }
  locales = [esLocale, enLocale];
  ngAfterContentInit(): void {
    this.authenticationService.currentUser.subscribe((User) => {
      this.User = User;
      this.clinicNameTitle = `${User.clinicId} - ${User.clinicName} (${User.companyId})`;

      setTimeout(() => {
        this.route.params.subscribe((c) => {
          const clinicID: number = Number(c.clinicID);
          if (clinicID) {
            this.API.GetClinics(null, clinicID).subscribe((clinicsRes) => {
              const selectedClinic = clinicsRes.find(
                (cc) => cc.clinicId == clinicID
              );
              this.onViewAsClinicIdChange(selectedClinic);
              this.viewAsSelector.selectValue(selectedClinic);
              this.ActivateView();
            });
          } else {
            this.ActivateView();
          }
        });
      }, 300);

      this.API.HasTwoWayTextingEnabled(this.User.clinicId).subscribe(
        (r: { hasTwoWayTextingEnabled: boolean }) => {
          this.has2wayTxtEnabled = r.hasTwoWayTextingEnabled;
        }
      );
    });
  }

  public selected_appointmentTypeId: number;
  baseUrl: string;
  ClinicAppointmentTypes: ClinicAppointmentType[] = [];
  public clinicAppointmentType: ClinicAppointmentType;
  public clinicDoctorAssistanceStatusDictionary: any;
  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Citas por página';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.previousPageLabel = 'Anterior';
  }

  @ViewChild('sms_panel') chatMenuTrigger: MatMenuTrigger;
  @ViewChild('doctorsFilter') doctorsFilterSelect: MatSelect;
  @ViewChild('statusFilter') estatusFilter: MatSelect;
  @ViewChild('viewAsSelector')
  viewAsSelector: MasterUserViewAsSelectorComponent;
  public has2wayTxtEnabled: Boolean = false;
  public view: HomeViewModel = {} as HomeViewModel;
  introJS = introJs();
  public chatAppointment: IAppointmentListItem = null;
  public selectedDoctorsFilter = new UntypedFormControl();
  public selectedStatusFilter = new UntypedFormControl();
  public isLinear: boolean = true;
  public isPreloading: boolean = true;
  expandedElement: IAppointmentListItem | null;
  public NoCallDates: INoCallDate[];
  public selectedSummaryCallType: ISummaryCallType;
  public selectedSummaryCallTypeId: number;
  public appointmentsTotal: number;
  public selectedSummaryCallTypeTabIndex: number = 0;
  public selectedDate: Date = new Date();
  public doctors: Doctor[] = new Array();
  public dateTitle: string = '';
  public lastSyncDate: string = '';
  public lastSyncForDate: string = '';
  public viewIsLoadingAppointments: boolean = true;
  public ViewAs: AdminViewAsClinicDTO;
  public AppointmentListItems: IAppointmentListItem[];
  public User: User;
  public showSmsColumn: boolean = false;
  public IsActivatedView: boolean = false;
  public sys_message_has_been_shown: boolean = false;
  public ShowTelefonoAlternoColumn: boolean = false;
  public ShowRecordColumn: boolean = false;
  public callStatuses: CallStatusGET_DTO[];
  private calendar_api: any;
  public clinicNameTitle: string = '';
  selected = new UntypedFormControl(0);
  calendarPlugins = [
    dayGridPlugin,
    // interactionPlugin
  ]; // important!
  displayedColumns: string[] = [];
  assistanceData: ClinicDoctorAssistanceStatusDTO[] = [];

  dataSource: MatTableDataSource<IAppointmentListItem>;
  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  appointmentsSearchTerm: string = '';
  public isLoadingDoctors: boolean = false;

  ngAfterViewInit() {
    this.getClinicAssistanceStatusesConfig();
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.showAppointmentAssistance = r.includes(
        PermissionSlugs.APPOINTMENT_ASSISTANCE
      );
    });
  }

  public openChat(appointment: IAppointmentListItem) {
    this.chatMenuTrigger.openMenu();
    this.chatAppointment = appointment;
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.router.navigate(['/admin-view-as/' + this.ViewAs.clinicId]);

    this.clinicNameTitle = `${this.ViewAs.clinicId} - ${this.ViewAs.clinicName} (${this.ViewAs.companyId})`;
  }

  public LoadClinicAppointmentTypes() {
    this.API.GetClinicAppointmentTypes(
      this.ViewAs?.clinicId || this.User.clinicId
    ).subscribe((r: ClinicAppointmentType[]) => {
      this.ClinicAppointmentTypes = r;
    });
  }

  public FetchDoctors() {
    this.isLoadingDoctors = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;

    this.API.GetAllDoctors(clinicid).subscribe(
      (result) => {
        var res = result as Doctor[];
        this.doctors = res;
        this.isLoadingDoctors = false;
      },
      (error) => console.error(error)
    );
  }

  handleCalendarDateClick(args: DateSelectArg) {
    this.selectedDate = args.start;
    const calendarApi = args.view.calendar;
    calendarApi.removeAllEvents();
    calendarApi.addEvent({
      start: args.start,
      allDay: true,
    });
    this.LoadAppointments();
  }

  DownloadAppointmentsReport(ExportType: string) {
    let userID = this.User.userId;
    if (this.ViewAs) userID = this.ViewAs.viewAsUserID;

    let link =
      this.baseUrl +
      `Reports/AppointmentsReport?StartDate=${moment(this.selectedDate).format(
        'YYYY-MM-DD'
      )}&EndTime=${moment(this.selectedDate).format('YYYY-MM-DD')}&CallTypeId=${
        this.selectedSummaryCallType.callTypeId
      }&ExportType=${ExportType}&userID=${userID}`;

    if (ExportType === 'PDF') {
      let data: reportsViewerData = {
        link: link,
      };
      this.dialog
        .open(ReportsViewerComponent, {
          data: data,
          width: '80%',
          disableClose: true,
        })
        .afterClosed()
        .subscribe((dialogResult) => {
          let result = dialogResult as boolean;
          if (result) this.LoadAppointments();
        });
    } else {
      window.open(link, '_blank');
    }
  }

  onCallTypesTabChange(TabChangeEvent: any) {
    let selected_summaryCallType =
      this.view.summaryCallTypes[TabChangeEvent.index];
    this.selectedSummaryCallType = selected_summaryCallType;
    this.selectedSummaryCallTypeId = selected_summaryCallType.callTypeId;
    if (this.IsActivatedView) this.LoadAppointments();
  }

  UpdateAppointmetCallStatus(apointment: IAppointmentListItem) {
    let data: UpdateAppointmentCallStatusDialogData = {
      appointment: apointment,
      callStatusID: apointment.callStatusId,
      callTypeId: this.view.callTypeId,
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      callStatuses: this.callStatuses,
    };
    this.dialog
      .open(UpdateAppointmentCallStatusDialogComponent, {
        data: data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  OpenAppointmentAddDialog() {
    let data: AddAppointmentDialogData = {
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      UserId: this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId,
      ClinicName: this.ViewAs ? this.ViewAs.clinicName : this.User.clinicName,
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data: data,
        width: '45%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) {
          this.LoadAppointments();
        }
      });
  }

  OpenUpdateAppointmetDialog(apointment: IAppointmentListItem) {
    let data: UpdateAppointmentDialogData = {
      appointment: apointment,
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      callStatuses: this.callStatuses,
    };
    this.dialog
      .open(UpdateAppointmentDialogComponent, {
        data: data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  public OpenAppointmentsDetailDialog(apointment: IAppointmentListItem) {
    let data: AppointmentDetailsDialogData = { appointmentData: apointment };
    this.dialog
      .open(AppointmentDetailsDialogComponent, {
        data: data,
        width: '40%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  public PauseConfirmations() {
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const date = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.NO_CALL_DATE_PauseAllForClinic(clinicId, date).subscribe(() =>
      this.LoadAppointments()
    );
  }

  public ResumeConfirmations() {
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const date = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.ResumeAllAppointmentsForClinic(clinicId, date).subscribe(() =>
      this.LoadAppointments()
    );
  }

  public LoadDetail(apointment: IAppointmentListItem) {
    apointment._isLoadingDetails = true;
    this.API.GetAppointmentDetails(
      apointment.clinicId,
      apointment.appointmentId
    ).subscribe(
      (result) => {
        var res = result as AppointmentDetails;

        res.details.forEach((e) => {
          e._formatedDate = moment(e.callTime).format(
            'DD MMM [del] YYYY[,] hh:mm A'
          );
        });

        apointment.CallHistory = res.details;

        apointment._callDate = moment(res.attempts.callDate).format(
          'DD MMMM [del] YYYY'
        );

        apointment.timing = res.attempts.timings.filter((e) => {
          if (
            apointment.CallHistory.map((eee) => eee.attempt).indexOf(
              e.callOrder
            ) > -1
          )
            return false;
          else return true;
        });

        apointment.timing.forEach((t) => {
          t.callTimeUnformatted = t.callTime;
          t.callTime = moment(
            '2020-01-01 ' + t.callTime,
            'YYYY-MM-DD HH:mm:ss.SSSS'
          ).format('hh:mm a');
        });
        apointment._isLoadingDetails = false;
      },
      () => {}
    );
  }

  public GetLastSyncDate(userid: number) {
    this.API.GetLastSyncDate(userid).subscribe(
      (res: {
        lastSyncDate: null | undefined | Date;
        appointmentDate: null | undefined | Date;
      }) => {
        if (res.lastSyncDate) {
          this.lastSyncDate = moment(res.lastSyncDate)
            .locale('es')
            .format('DD MMM YYYY');
          this.lastSyncForDate = moment(res.appointmentDate)
            .locale('es')
            .format('DD MMM YYYY');
        } else {
          this.lastSyncDate = null;
          this.lastSyncForDate = null;
        }
      }
    );
  }

  public GetSystemMessage(userid: number) {
    this.API.GetSystemMessage(userid).subscribe(
      (res: { system_Message: system_message }) => {
        if (res.system_Message && !this.sys_message_has_been_shown) {
          let config: MatDialogConfig = {
            minWidth: '100%',
            disableClose: true,
            panelClass: 'due_balance_dialog_container',
            data: res.system_Message,
          };
          this.dialog
            .open(BalanceDueComponent, config)
            .afterClosed()
            .subscribe(() => {
              this.dialog.closeAll();
              this.sys_message_has_been_shown = true;

              this.API.CreateNewViewLogSystemMessage(
                this.User.userId,
                res.system_Message.systemAnnouncementId
              );
            });
        }
      }
    );
  }

  public clearSelectedDoctorsFilter() {
    this.doctorsFilterSelect.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.firstPage();
  }

  public onDoctorsFilterChange() {
    const selectedDoctors: Array<any> =
      this.selectedDoctorsFilter?.value?.map((v) => v.doctorId) || [];

    const selectedStatuses: Array<any> =
      this.selectedStatusFilter?.value?.map((v) => v.callStatusId) || [];

    if (selectedDoctors.length) {
      this.dataSource = new MatTableDataSource<IAppointmentListItem>(
        this.AppointmentListItems.filter((f) => {
          let result = selectedDoctors.includes(f.doctorId);
          if (selectedStatuses.length && result) {
            result = selectedStatuses.includes(f.callStatusId);
          }
          return result;
        })
      );
    } else {
      if (selectedStatuses.length) {
        this.onStatusFilterChange();
      } else {
        this.dataSource = new MatTableDataSource<IAppointmentListItem>(
          this.AppointmentListItems
        );
      }
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.firstPage();
  }

  public onStatusFilterChange() {
    const selectedDoctors: Array<any> =
      this.selectedDoctorsFilter?.value?.map((v) => v.doctorId) || [];

    const selectedStatuses: Array<any> =
      this.selectedStatusFilter?.value?.map((v) => v.callStatusId) || [];

    if (selectedStatuses.length) {
      this.dataSource = new MatTableDataSource<IAppointmentListItem>(
        this.AppointmentListItems.filter((f) => {
          let result = selectedStatuses.includes(f.callStatusId);
          if (selectedDoctors.length && result) {
            result = selectedDoctors.includes(f.doctorId);
          }
          return result;
        })
      );
    } else {
      if (selectedDoctors?.length) {
        this.onDoctorsFilterChange();
      } else {
        this.dataSource = new MatTableDataSource<IAppointmentListItem>(
          this.AppointmentListItems
        );
      }
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.firstPage();
  }

  public clearStatusFilter() {
    this.estatusFilter.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.firstPage();
  }

  LoadAppointments() {
    this.viewIsLoadingAppointments = true;
    let CallTypeId = 1; // default = confirmaciones;

    try {
      if (this.selectedSummaryCallType)
        CallTypeId = this.selectedSummaryCallType.callTypeId;
    } catch (e) {
      CallTypeId = 1; // default = confirmaciones;
    }
    let $date = moment(this.selectedDate).format('YYYY-MM-DD');
    let viewAsUserID = this.User.userId;
    if (this.ViewAs) viewAsUserID = this.ViewAs.viewAsUserID;

    this.API.GetAppointmentsSummary(
      $date,
      CallTypeId,
      viewAsUserID,
      this.selected_appointmentTypeId
    ).subscribe(
      (result) => {
        var res = result as HomeViewModel;
        this.IsActivatedView = true;

        this.NoCallDates = res.nocallDates;

        const _appointmentsMapped = res.appointments.map((e, index) => {
          return {
            ...e,
            _appointmentDateUnformatted: moment(e.appointmentDate).toDate(),
            _lastCallUnformatted: e.lastCall
              ? moment(e.lastCall).toDate()
              : null,
            appointmentDate: moment(e.appointmentDate).format('hh:mm A'),
            lastCall: e.lastCall
              ? moment(e.lastCall).locale('es').format('DD MMM YYYY hh:mm A')
              : null,
            is_gray: index % 2 == 0,
          };
        });

        if (res.appointments.some((c) => c.unreadSmsResponsesCount > 0)) {
          const unreadApts = _appointmentsMapped.filter(
            (aa) => aa.unreadSmsResponsesCount > 0
          );

          const nonUnreadApts = _appointmentsMapped.filter(
            (aa) => !aa.unreadSmsResponsesCount
          );

          res.appointments = [
            ..._.orderBy(unreadApts, ['unreadSmsResponsesCount'], 'desc'),
            ...nonUnreadApts,
          ];
        } else {
          res.appointments = _appointmentsMapped;
        }

        this.dateTitle = moment($date)
          .locale('es')
          .format('dddd DD [de] MMMM [del] YYYY');

        this.view = res;

        this.view.statusSummary = res.statusSummary;

        this.selectedSummaryCallType = this.view.summaryCallTypes.find(
          (c) => c.callTypeId == CallTypeId
        );

        this.AppointmentListItems = res.appointments;

        this.dataSource = new MatTableDataSource<IAppointmentListItem>(
          this.AppointmentListItems
        );

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator.firstPage();

        if (this.selectedSummaryCallTypeId) {
          this.selectedSummaryCallTypeTabIndex =
            this.view.summaryCallTypes.indexOf(this.selectedSummaryCallType);
        }
        this.viewIsLoadingAppointments = false;

        this.appointmentsTotal = this.view.statusSummary
          .map((e) => e.statusCount)
          .reduce((a, b) => a + b, 0);

        this.showSmsColumn = this.view.appointments.some((e) => e.smsResponse);

        this.ShowRecordColumn = this.view.appointments.some(
          (e) => e.patientRecord
        );

        this.ShowTelefonoAlternoColumn = this.view.appointments.some(
          (e) => e.patientAltPhoneNumber
        );

        let selected_summaryCallType = this.view.summaryCallTypes.filter(
          (eee) => eee.callTypeId == res.callTypeId
        )[0];

        selected_summaryCallType = selected_summaryCallType || {
          callTypeId: 1,
          callTypeName: 'Confirmation',
          callTypeAlias: 'Confirmación',
          description: 'Ver resumen de estatus de llamadas de confirmación.',
        };

        this.selectedSummaryCallType = selected_summaryCallType;
        this.selectedSummaryCallTypeId = selected_summaryCallType.callTypeId;
        this.isPreloading = false;
        this.SetCalendarSize();
      },
      (error) => console.error(error)
    );
  }

  getDisplayedColumns() {
    let columns = [
      'patientName',
      'Telefono',
      this.ShowTelefonoAlternoColumn ? 'TelefonoAlterno' : null,
      'reminderSetName',
      'appointmentDate',
      'UltimoIntento',
      this.showSmsColumn ? 'smsResponse' : null,
      'callStatusName',
      this.showAppointmentAssistance ? 'assistanceStatusId' : null,
      'Acciones',
    ].filter((e) => e);

    return columns;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  LoadCallStatuses() {
    let clinicID = this.User.clinicId;
    if (this.ViewAs) clinicID = this.ViewAs.clinicId;

    this.API.GetAllCallStatus(clinicID).subscribe((result) => {
      this.callStatuses = result as CallStatusGET_DTO[];
    });
  }

  StartDemo() {
    this.introJS.start();
  }

  calendarOptions: CalendarOptions = {
    editable: true,
    selectable: true,
    select: this.handleCalendarDateClick.bind(this),
    height: '380px',
    locale: 'es',
    locales: this.locales,
    plugins: [dayGridPlugin, interactionPlugin],
  };

  SetCalendarComponent() {
    let calendarApi = this.calendarComponent.getApi();
    this.calendar_api = calendarApi;
    this.calendar_api.setOption('aspectRatio', 1);
    this.calendar_api.select(this.selectedDate);

    this.calendarComponent.getApi().setOption('selectable', true); // = true;
    this.SetCalendarSize();
  }

  public SetCalendarSize() {
    // const calApi = this.calendarComponent?.getApi();
    // if (calApi) {
    //   // calApi.setOption("height", 500);
    //   calApi.updateSize();
    // }
  }

  ActivateView() {
    this.viewIsLoadingAppointments = true;

    if (this.calendarComponent) {
      this.SetCalendarComponent();
    }

    this.LoadAppointments();
    this.LoadCallStatuses();

    // this.User = this.authenticationService.currentUserValue;

    this.introJS.setOptions({
      steps: [
        {
          element: '#navbarLogo',
          intro: '¡Bienvenid@ a Citamed!',
          position: 'bottom',
        },
        {
          element: '#step2',
          intro:
            'Para cargar el listado de confirmaciones de un día en especifico, haz click en el.',
          position: 'right',
        },
        {
          element: '#step3',
          intro:
            'Acá podrás ver quienes confirmaron o no las citas, dependiendo del tipo de llamada.',
          position: 'top',
        },
        {
          element: '#step4',
          intro:
            'Aquí puedes filtar por el tipo de llamada (Confirmacion de Citas, llamada especial, Recordatorios etc...)',
          position: 'right',
        },
        {
          element: '#step5',
          intro:
            'Si necesitas descargar el reporte de citas, haz click en este botón y selecciona el formato deseado',
          position: 'right',
        },
        {
          element: '#step6',
          intro:
            'Aquí podrás ver el listado de pacientes para fecha y tipo de llamada seleccionadas.',
          position: 'right',
        },
        {
          element: '#step7',
          intro:
            'Si quieres buscar una cita en especifico puedes filtrarla en este campo, utilizando el nombre del paciente, su teléfono, número de record o nombre del doctor asignado a la cita.',
          position: 'right',
        },
        {
          element: '#step8',
          intro:
            'Haz click en el icono del estatus en esta columna para editar el estatus de la cita (confirmarla/cancelarla etc...)',
          position: 'right',
        },
        {
          element: '#step9',
          intro: 'Haz click en el lápiz de una cita para editarla.',
          position: 'right',
        },
        {
          element: '#step10',
          intro:
            'Haz click en la cabecera de la columna para organizar las citas por ellas.',
          position: 'right',
        },
        {
          element: '#step11',
          intro: 'Haz click aquí para añadir una nueva cita.',
          position: 'right',
        },
        {
          element: '#step700',
          intro:
            'Recuerda, si necesitas ayuda puedes escribirnos en el recuadro verde al fondo de la pantalla llamado "Soporte Técnico".',
          position: 'bottom',
        },
      ],
      doneLabel: 'Listo',
      skipLabel: 'Saltar',
      prevLabel: 'Atrás',
      nextLabel: 'Siguiente',
      showProgress: true,
    });

    let snackBar = this._snackBar.open(
      '¡Bienvenido a Citamed!, haz click en este botón para aprender a utilizar el sistema',
      'Ver tutorial ',
      {
        duration: 8000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      }
    );

    snackBar.onAction().subscribe(() => {
      this.StartDemo();
    });
    this.viewIsLoadingAppointments = false;
    this.FetchDoctors();
    this.LoadClinicAppointmentTypes();

    let viewAsUserID = this.User.userId;
    if (this.ViewAs) viewAsUserID = this.ViewAs.viewAsUserID;

    this.GetLastSyncDate(viewAsUserID);
    this.GetSystemMessage(viewAsUserID);
  }

  public deleteAppointment(appointmentId: number) {
    let confirmed = window.confirm(
      '¿Estás segur@ de querer eliminar esta entrada?'
    );
    if (confirmed) {
      this.isPreloading = true;

      try {
        this.API.DeleteAppointmentCallSchedule(
          appointmentId,
          this.selectedSummaryCallType.callTypeId
        ).subscribe(() => {
          this.isPreloading = false;
          this.LoadAppointments();
        });
      } catch (e) {
        this.isPreloading = false;
        window.alert('Ha ocurrido un error.');
      }
    }
  }

  public getClinicAssistanceStatusesConfig() {
    let clinicID = this.User.clinicId;
    if (this.ViewAs) clinicID = this.ViewAs.clinicId;

    this.API.GetClinicAssistanceConfig(clinicID).subscribe(
      (data: ClinicDoctorAssistanceStatusDTO[]) => {
        this.assistanceData = data;
        const dictionary = {};

        data.forEach(
          (d) => (dictionary[`${d.assistanceStatusID}-${d.doctorId}`] = d)
        );

        this.clinicDoctorAssistanceStatusDictionary = dictionary;
      }
    );
  }

  public updateAppointmentAssistanceStatus(
    appointment: IAppointmentListItem,
    statusId: number
  ) {
    const onError = () => {
      alert('An error has occurred.');
    };
    this.API.UpdateAppointmentAssistanceStatus(
      statusId,
      appointment.appointmentId,
      appointment.clinicId
    ).subscribe(() => {
      const message = this.assistanceData.find(
        (c) =>
          c.doctorId == appointment.doctorId && c.assistanceStatusID == statusId
      );
      if (message.messageTemplate?.length) {
        this.API.SendSms({
          AppointmentId: appointment.appointmentId,
          ToNumber: appointment.patientPhoneNumber,
          MessageContent: message.messageTemplate,
        }).subscribe(() => {
          this._snackBar.open(
            `Mensaje de texto enviado a ${appointment.patientName} con el contenido "${message.messageTemplate}"`,
            null,
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }, onError);
      }
    }, onError);
  }
}
