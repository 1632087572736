import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import AppointmentTypeDto from 'src/app/DTOS/Appointments-types/appointmentType';
import { AppointmentDetails } from './_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import { AdminViewAsClinicDTO } from './dtos/AdminViewAsClinicDTO';
import {
  ApiUserDTO,
  AssociateDTO,
  IApiUserList,
} from './DTOS/ApiUsers/ApiUsersDTO';
import {
  ClinicAssistanceViewModel,
  HomeViewModel,
} from './DTOS/Appointments/AppointmentListItem';
import { ClinicDoctorAssistanceStatusDTO } from './DTOS/AssistanceStatus';
import { CallStatusGET_DTO } from './dtos/CallStatus/DTOS';
import { CallTimingSetGET } from './DTOS/CallTiming/CallTiming';
import { CalltimingSet } from './DTOS/CalltimingSet/CallTimingSetGetDTO';
import { CallTimingSprocDTO } from './DTOS/CallTimingSproc/CallTimingSproc';
import { GetCallTimingSprocDTO } from './DTOS/CallTimingSproc/GetCallTimingSprocDTO';
import { CallType } from './DTOS/CallType/CallType';
import { ClinicConfigDTO, ClinicDetails } from './DTOS/Clinic/clinic-details';
import { Doctor, GoogleTokenId } from './Dtos/Doctors/DoctorDTO';
import { GoogleCalendarDto } from './DTOS/GoogleCalendars/GoogleCalendarDto';
import { InsurancePlansDTO } from './DTOS/InsurancePlans/InsurancePlansDTO';
import {
  NoCallDateCreateDTO,
  NoCallDateGetDTO,
} from './dtos/NoCallDate/NoCallDateCreateDTO';
import {
  PatientDetail,
  PatientPhoneNumber,
} from './DTOS/Patient/Patient-Detail';
import {
  AddPermissionDTO,
  ClinicPermissionDTO,
} from './DTOS/Permission/PermissionDTO';
import { RatePlan } from './DTOS/RatePlans/RatePlan';
import { ReminderScheduleGet } from './DTOS/ReminderSchedule/ReminderScheduleGet';
import { ReminderSetDto } from './dtos/ReminderSets/ReminderSetDto';
import { ClinicStatByStatus } from './DTOS/Reports/ClinicStatByStatus';
import { SpecialAnnouncementCreateDTO } from './dtos/SpecialAnnouncement/SpecialAnnouncement';
import { Speciality } from './DTOS/Speciality/Speciality';
import {
  TCMDetail,
  TransitionalCareConfigMessageDTO,
} from './DTOS/TransitionalCareAdmisionsAndDischarges/TransitionalCareAdmisionsAndDischarges';
import { PasswordChangeDTO, UserEditDTO, UserGetDTO } from './dtos/Users/Users';
import { GetAccountWithClinicInfo } from './DTOS/VA_Configurations/GetAccountWithClinicInfo';
import {
  VirtualAssistantAccountDTO,
  VirtualAssistantDoctorsDTO,
  VirtualAssistantDoctorTagsDTO,
  VirtualAssistantServicesDTO,
  VirtualAssistantServiceTagsDTO,
} from './DTOS/VA_Configurations/VA_Configurations';
import * as moment from 'moment';
import { BookingService } from './DTOS/Bookings/BookingServiceDTO';
import * as qs from 'qs';
import {
  BookingAppointments,
  BookingAppointmentsList,
} from './DTOS/Bookings/BookingAppointmentsDTO';
import { GetBookingsRequest } from './DTOS/Bookings/GetBookingsRequest';
import { BookingModel } from './DTOS/Bookings/BookingDTO';
import {
  WhiteListedPhoneNumbers,
  WhiteListedPhoneNumbersList,
} from './DTOS/Clinic/WhiteListedPhoneNumbersDTO';
import { AddCompanyDTO } from './DTOS/Company/AddCompanyDTO';
import {
  EngineSetting,
  IEngineSettingsList,
} from './DTOS/Admin/AdminViewAsClinic';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpClient: HttpClient;
  baseUrl: string;
  cookieValue: string;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    cookieService: CookieService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.cookieValue = cookieService.get('docalerts');
  }

  public GET_BASE_URL() {
    const base = this.baseUrl;
    return base;
  }

  public CreateAppointmentType(dto: AppointmentTypeDto) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'AppointmentTypes/CreateAppointmentType',
      dto
    );
  }

  public UpdateAppointmentType(dto: AppointmentTypeDto) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'AppointmentTypes/UpdateAppointmentType',
      dto
    );
  }

  public GetAppointmentTypes() {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'AppointmentTypes/GetAppointmentTypes'
    );
  }

  public GetClinicAppointmentTypes(clinicid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        'AppointmentTypes/GetClinicAppointmentTypes?clinicid=' +
        clinicid
    );
  }

  public ToggleAppointmentTypeForClinic(
    Clinicid: number,
    AppointmentTypeId: number,
    onOrOff: boolean
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `AppointmentTypes/ToggleAppointmentTypeForClinic?clinicid=${Clinicid}&AppointmentTypeId=${AppointmentTypeId}&onOrOff=${onOrOff}`,
      {}
    );
  }
  public FetchDoctors(ClinicID: number) {
    return this.httpClient.get<Doctor[]>(
      this.GET_BASE_URL() + 'Doctors/GetAll?ClinicID=' + ClinicID
    );
  }
  public FetchNoCallDates(ClinicID: number) {
    return this.httpClient.get<NoCallDateGetDTO[]>(
      this.GET_BASE_URL() + 'nocalldates/GetNoCallDates?ClinicID=' + ClinicID
    );
  }
  public CreateNoCallDate(noCallDateDTO: NoCallDateCreateDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'NoCallDates/SaveNoCallDate',
      noCallDateDTO
    );
  }

  public DeleteNoCallDate(NoCallDateId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        'nocalldates/DeleteNoCallDate?NoCallDateID=' +
        NoCallDateId
    );
  }

  public RemoveNoCallDate(nocalldate: NoCallDateCreateDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'NoCallDates/RemoveNoCallDate',
      nocalldate
    );
  }

  public Login({ username, Password }: { username: string; Password: string }) {
    return this.httpClient.post<any>(this.GET_BASE_URL() + 'Auth/Login', {
      Identifier: username,
      Password,
    });
  }

  public GetAllUsers(clinicId: number) {
    return this.httpClient.get<UserGetDTO[]>(
      this.GET_BASE_URL() + 'users/getall?clinicid=' + clinicId
    );
  }

  public EditUser(user: UserEditDTO) {
    return this.httpClient.post(this.GET_BASE_URL() + 'users/EditUser', user);
  }
  public GetAllAppointments(clinicId: number, _date: string) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `Appointments/GetAppointments?clinicId=${clinicId}&AppointmentDate=${_date}`
    );
  }
  public GetAllDoctors(clinicId: number) {
    return this.httpClient.get<Doctor[]>(
      this.GET_BASE_URL() + `Doctors/GetAll?clinicId=${clinicId}`
    );
  }

  public GetAllReminderSet(clinicId: number) {
    return this.httpClient.get<ReminderSetDto[]>(
      this.GET_BASE_URL() + `ReminderSet/GetAll?clinicId=${clinicId}`
    );
  }
  public SendSpecialAnnouncement(dto: SpecialAnnouncementCreateDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'SpecialAnnouncement/SendSpecialAnnouncement',
      dto
    );
  }

  public GetReminderSetReminderSet(clinicId: number) {
    const route = 'reminderset/GetReminderSetReminderSet?clinicid=' + clinicId;
    return this.httpClient.get(this.GET_BASE_URL() + route);
  }

  public DeleteReminderSetReminderSet(reminderSetReminderSetId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `ReminderSet/DeleteReminderSetReminderSet?ReminderSet_ReminderSetID=` +
        reminderSetReminderSetId
    );
  }

  public CreateReminderSetReminderSet(dto: any) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ReminderSet/CreateReminderSetReminderSet',
      dto
    );
  }

  public UpdateReminderSchedule(e: ReminderScheduleGet) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ReminderSchedule/UpdateReminderSchedule',
      e
    );
  }

  public AddReminderSchedule(e: ReminderScheduleGet) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ReminderSchedule/AddReminderSchedule',
      e
    );
  }

  public DeleteReminderSchedule(reminderScheduleId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `ReminderSchedule/DeleteReminderSchedule?reminderScheduleId=` +
        reminderScheduleId
    );
  }

  public GetAllCallTypes() {
    return this.httpClient.get<CallType[]>(
      this.GET_BASE_URL() + 'calltypes/GetAll'
    );
  }

  public FetchCallTimingSets(ClinicID: number) {
    return this.httpClient.get<CallTimingSetGET[]>(
      this.GET_BASE_URL() + `Calltiming/GetAll?ClinicID=` + ClinicID
    );
  }

  public GetReminderSchedules(ClinicID: number) {
    return this.httpClient.get<ReminderScheduleGet[]>(
      this.GET_BASE_URL() +
        `reminderschedule/GetReminderSchedules?ClinicID=${ClinicID}`
    );
  }

  public CreatePasswordResetToken(Identifier: string) {
    return this.httpClient.post('PasswordReset/CreatePasswordResetToken', {
      Identifier,
    });
  }

  public NO_CALL_DATE_PauseAllForClinic(clinicId: number, date: string) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `nocalldates/PauseAllForClinic?clinicId=${clinicId}&date=${date}`,
      {}
    );
  }
  public ResumeAllAppointmentsForClinic(clinicId: number, date: string) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `nocalldates/ResumeAllAppointmentsForClinic?clinicId=${clinicId}&date=${date}`,
      {}
    );
  }

  public GetAppointmentDetails(clinicId: number, appointmentId: number) {
    return this.httpClient.get<AppointmentDetails>(
      this.GET_BASE_URL() +
        `appointments/GetAppointmentDetails?clinicid=${clinicId}&appointmentid=${appointmentId}`
    );
  }

  public GetAppointmentsSummary(
    date: string,
    CallTypeId: number,
    UserId: number,
    appointmentTypeID: number
  ) {
    return this.httpClient.get<HomeViewModel>(
      this.GET_BASE_URL() +
        `Appointments/GetAppointmentsSummary?date=${date}&CallTypeId=${CallTypeId}&UserId=${UserId}${
          appointmentTypeID ? '&appointmentTypeId=' + appointmentTypeID : ''
        }`
    );
  }

  public CreateNewViewLogSystemMessage(
    UserId: number,
    SystemAnnouncementId: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'SystemMessages/CreateNewViewLog',
      {
        UserId,
        SystemAnnouncementId,
      }
    );
  }

  public GetAllCallStatus(clinicID: number) {
    return this.httpClient.get<CallStatusGET_DTO[]>(
      this.GET_BASE_URL() + `CallStatus/GetAll?ClinicID=${clinicID}`
    );
  }

  public DeleteAppointmentCallSchedule(
    appointmentId: number,
    callTypeId: number
  ) {
    const route = `Appointments/DeleteAppointmentCallSchedule?AppointmentID=${appointmentId}&CallTypeID=${callTypeId}`;
    return this.httpClient.delete(this.GET_BASE_URL() + route);
  }

  public GetAllEhrs() {
    return this.httpClient.get(this.GET_BASE_URL() + 'EHRs/GetAll');
  }

  public GetAllCalltimings(clinicId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'Calltiming/GetCallTimingSets?clinicId=' + clinicId
    );
  }

  public UpdateCallTimingSet(calltimingSet: CalltimingSet) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'Calltiming/UpdateCallTimingSet',
      calltimingSet
    );
  }

  public CreateCallTimingSet(calltimingSet: CalltimingSet) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'Calltiming/CreateCallTimingSet',
      calltimingSet
    );
  }

  public DeleteCallTimingSet(CalltimingSetID: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'Calltiming/DeleteCallTimingSet?CalltimingSetID=' +
        CalltimingSetID
    );
  }

  public GetAllRatePlans() {
    return this.httpClient.get<RatePlan[]>(
      this.GET_BASE_URL() + 'rateplan/GetAall'
    );
  }

  public CreateRatePlan(newPlan: RatePlan) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'rateplan/Create',
      newPlan
    );
  }

  public DeleteRatePlan = (RateplanID: number) =>
    this.httpClient.delete(
      this.GET_BASE_URL() + 'rateplan/Delete?RateplanID=' + RateplanID
    );

  public GetSpecialities() {
    return this.httpClient.get<Speciality[]>(
      this.GET_BASE_URL() + 'speciality/GetAll'
    );
  }

  public CreateDoctor(doctor: {
    ClinicId: number;
    specialtyId: number | null;
    doctorName: string;
    doctorInternalIdentifier: string | null;
  }) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Doctors/CreateDoctor',
      doctor
    );
  }

  public UpdateDoctor(doctor: {
    ClinicId: number;
    doctorId: number;
    ClinicDoctorId: number;
    specialtyId: number | null;
    doctorName: string;
    doctorInternalIdentifier: string | null;
  }) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Doctors/EditDoctor',
      doctor
    );
  }

  public DeleteDoctor(doctorId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + 'Doctors/DeleteDoctor?doctorId=' + doctorId
    );
  }

  public GetClinicPhoneNumber(ClinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'clinics/GetClinicPhoneNumber?ClinicID=' + ClinicID
    );
  }

  public ActivateT_CallerID(ClinicID: number, extension: string) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `clinics/ActivateT_CallerID?ClinicID=${ClinicID}${
          extension ? `&extension=${extension}` : ''
        }`
    );
  }

  public GetClinic(ClinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'clinics/GetClinic?ClinicID=' + ClinicID
    );
  }

  public GetClinicConfiguration(clinicID: number) {
    const route =
      this.GET_BASE_URL() + `Clinics/GetConfig?ClinicId=${clinicID}`;

    return this.httpClient.get(route);
  }

  public UpdateEHR(clinicConfig: ClinicConfigDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'clinics/UpdateConfig',
      clinicConfig
    );
  }

  public UpdateClinic(clinic: ClinicDetails) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'clinics/UpdateClinic',
      clinic
    );
  }

  public CreateClinic(clinic: ClinicDetails) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'clinics/CreateClinic',
      clinic
    );
  }

  public HasTwoWayTextingEnabled(clinicid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        'clinics/HasTwoWayTextingEnabled?clinicid=' +
        clinicid
    );
  }

  public SearchCompany(searchTerm: string) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'company/SearchCompany?searchTerm=' + searchTerm
    );
  }
  public CreateCompany(companyDTO: AddCompanyDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Company/CreateCompany',
      companyDTO
    );
  }
  public UpdateCompany(companyDTO: AddCompanyDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Company/UpdateCompany',
      companyDTO
    );
  }
  public GetCompany() {
    return this.httpClient.get(this.GET_BASE_URL() + 'Company/GetCompany');
  }
  public GetCompanyById(companyId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Company/GetCompanyById?companyId=${companyId}`
    );
  }

  public GetClinicCallAgainSchedules(clinicId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'CallAgainSchedule/GetByClinic?clinicid=' + clinicId
    );
  }
  public GetAccountStatsByCallStatus(callDate: Date) {
    const date = moment(callDate).format('YYYY-MM-DD');
    return this.httpClient.get<ClinicStatByStatus[]>(
      this.GET_BASE_URL() + 'accounts/GetStatsByCallStatus?calldate=' + date
    );
  }
  public GetAccountStats() {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'accounts/GetAccountStats'
    );
  }

  public GetMissedCall(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    fromFormat: string | null,
    toFormat: string | null,
    searchTearm: string,
    selectedIntentTypes: number[] | null
  ) {
    let route = `VA/${clinicID}/IncomingCalls/GetIncomingCalls?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&from=${fromFormat}&To=${toFormat}&searchTerm=${searchTearm}`;

    if (selectedIntentTypes && selectedIntentTypes.length) {
      route = route + '&intents=' + selectedIntentTypes.join(',');
    }
    return this.httpClient.get(this.GET_BASE_URL() + route);
  }

  public DeleteMissedCall(clinicID: number, CallIds: number[]) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${clinicID}/IncomingCalls`,
      CallIds
    );
  }

  public GetAppointmentsRequest(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    fromFormat: string | null,
    toFormat: string | null,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${clinicID}/AppointmentRequests/GetList?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&from=${fromFormat}&To=${toFormat}&searchTerm=${searchTearm}`
    );
  }

  public GetAllPermissions(HasValue: boolean) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'Permission/GetAll?HasValue=' + HasValue
    );
  }
  public CreatePermission(permission: AddPermissionDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Permission/CreatePermission',
      permission
    );
  }
  public UpdatePermission(permission: AddPermissionDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Permission/EditPermission',
      permission
    );
  }

  public GetAllClinicPermissions(clinicid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + 'ClinicPermissions/GetAll?ClinicID=' + clinicid
    );
  }

  public CreateClinicPermission(clicPermissionDTO: ClinicPermissionDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'ClinicPermissions/CreateClinicPermission',
      clicPermissionDTO
    );
  }

  public DeleteClinicPermissions(clinicPermissionId: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `ClinicPermissions/DeleteClinicPermission`,
      clinicPermissionId
    );
  }
  public PasswordChange(passwordChageDTO: PasswordChangeDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'Auth/PasswordChange',
      passwordChageDTO
    );
  }

  public LoadAccount(accountId: number) {
    return this.httpClient.get(this.GET_BASE_URL() + `VA/${accountId}/Config`);
  }
  public AddAccount(accountId: number, account: VirtualAssistantAccountDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/Config`,
      account
    );
  }
  public UpdateAccount(accountID: number, account: VirtualAssistantAccountDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${accountID}/Config`,
      account
    );
  }

  public CreateDoctorVirtualAssistant(
    accountId: number,
    doctor: VirtualAssistantDoctorsDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/Doctors/CreateDoctor`,
      doctor
    );
  }

  public LoadListDoctorVirtualAssistant(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${clinicID}/Doctors/GetList?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public UpdateDoctorVirtualAssistant(
    accountID: number,
    doctor: VirtualAssistantDoctorsDTO
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${accountID}/Doctors`,
      doctor
    );
  }
  public DeleteDoctorVirtualAssistant(accountID: number, doctorID: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/Doctors/DeleteDoctor`,
      doctorID
    );
  }

  public CreateServiceVirtualAssistant(
    accountId: number,
    doctor: VirtualAssistantServicesDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/Services`,
      doctor
    );
  }

  public LoadListServicesVirtualAssistant(
    clinicID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${clinicID}/Services/GetList?accountId=${clinicID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public UpdateServiceVirtualAssistant(
    accountID: number,
    sevices: VirtualAssistantServicesDTO
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `VA/${accountID}/Services`,
      sevices
    );
  }
  public DeleteServiceVirtualAssistant(accountID: number, serviceID: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + `VA/${accountID}/Services?serviceID=${serviceID}`,
      {}
    );
  }

  public CreateDoctorTagsVirtualAssistant(
    accountId: number,
    DoctorTag: VirtualAssistantDoctorTagsDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/DoctorsTags/CreateDoctorTag`,
      DoctorTag
    );
  }

  public GetListDoctorTagsVirtualAssistant(
    accountId: number,
    doctorID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${accountId}/DoctorsTags/GetListDoctorTags?doctorID=${doctorID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public DeleteSDoctorTagsVirtualAssistant(
    accountID: number,
    doctorTagID: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/DoctorsTags/DeleteDoctorTags`,
      doctorTagID
    );
  }

  public CreateServiceTagsVirtualAssistant(
    accountId: number,
    ServiceTag: VirtualAssistantServiceTagsDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountId}/ServicesTags/CreateServiceTag`,
      ServiceTag
    );
  }

  public GetListServiceTagsVirtualAssistant(
    accountId: number,
    serviceID: number,
    currentPage: number,
    pageSize: number,
    searchTearm: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `VA/${accountId}/ServicesTags/GetListServiceTags?serviceID=${serviceID}&page=${currentPage}&pagesize=${pageSize}&searchTerm=${searchTearm}`
    );
  }

  public DeleteServiceTagsVirtualAssistant(
    accountID: number,
    serviceTagID: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `VA/${accountID}/ServicesTags/DeleteServiceTags`,
      serviceTagID
    );
  }

  public GetIntentTypes(accountID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `VA/${accountID}/Config/GetIntentTypes`
    );
  }

  public GetLastSyncDate(userid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Appointments/GetLastSyncDate?userid=${userid}`
    );
  }

  public GetSystemMessage(userid: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Appointments/GetSystemMessage?userid=${userid}`
    );
  }

  public GetUserPermissions() {
    return this.httpClient.get(this.GET_BASE_URL() + 'auth/GetPermissions');
  }

  public GetTransitionalCareRecords(
    clinicID: number,
    AppointmentType: number,
    fromDate: string | null,
    toDate: string | null,
    page: number,
    quantity: number,
    sortByColumn?: string | null,
    sortByDirection?: string | null,
    searchTerm?: string | null
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `TC/TransitionalCareRecords/GetListRecords?clinicID=${clinicID}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&quantity=${quantity}&sortByColumn=${
          sortByColumn || 'admitionDate'
        }&sortByDirection=${sortByDirection || 'desc'}${
          searchTerm ? `&searchTerm=${searchTerm}` : ''
        }${AppointmentType ? `&appointmentTypeID=${AppointmentType}` : ''}`
    );
  }
  public GetResumenTransitionalCare(
    clinicID: number,
    fromDate: string,
    toDate: string
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `TC/TransitionalCareRecords/GetResumenTransitionalCare?clinicID=${clinicID}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  public CreateTransitionalCareMessage(
    transitionalCareConfigMessageDTO: TransitionalCareConfigMessageDTO
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `TC/TransitionalCareConfigMessages/CreateMessage`,
      transitionalCareConfigMessageDTO
    );
  }

  public UpdateTransitionalCareMessage(
    transitionalCareConfigMessage: TransitionalCareConfigMessageDTO
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `TC/TransitionalCareConfigMessages/UpdateMessage`,
      transitionalCareConfigMessage
    );
  }

  public GetTransitionalCareMessage(
    clinicID: number,
    appointmentTypeID: number
  ) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `TC/TransitionalCareConfigMessages/GetListRecords?clinicID=${clinicID}&appointmentTypeID=${appointmentTypeID}`
    );
  }

  public DeleteTransitionalCareMessageTemplaste(
    clinicID: number,
    MessageTemplateId: number
  ) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `TC/TransitionalCareConfigMessages/DeleteMessage?clinicID=${clinicID}&MessageTemplateId=${MessageTemplateId}`
    );
  }

  public ActiveTransitionalCareMessageTemplaste(
    clinicID: number,
    MessageTemplateId: number
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `TC/TransitionalCareConfigMessages/ActiveMessage?clinicID=${clinicID}&MessageTemplateId=${MessageTemplateId}`,
      {}
    );
  }

  public GetClinicAssistanceConfig(clinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `Assistance/GetConfig?ClinicID=${clinicID}`
    );
  }

  public GetAssistanceStatuses() {
    const route = this.GET_BASE_URL() + `Assistance/GetAssistanceStatuses`;
    return this.httpClient.get(route);
  }

  public DeleteAssistanceStatusConfig(clinicDoctorAssistanceStatusId: number) {
    const route =
      this.GET_BASE_URL() +
      `Assistance/DeleteAssistanceStatusConfig?ClinicDoctorAssistanceStatusId=${clinicDoctorAssistanceStatusId}`;
    return this.httpClient.delete(route);
  }

  public CreateNewAssistanceStatusConfig(
    ClinicDoctorAssistanceStatusDTO: ClinicDoctorAssistanceStatusDTO
  ) {
    const route =
      this.GET_BASE_URL() + `Assistance/CreateNewAssistanceStatusConfig`;
    return this.httpClient.post(route, ClinicDoctorAssistanceStatusDTO);
  }
  public CreateNewAssistanceStatus(payload: ClinicAssistanceViewModel) {
    const route = this.GET_BASE_URL() + `Assistance/CreateNewAssistanceStatus`;
    return this.httpClient.post(route, payload);
  }

  public UpdateAssistanceStatus(payload: ClinicAssistanceViewModel) {
    const route = this.GET_BASE_URL() + `Assistance/UpdateNewAssistanceStatus`;
    return this.httpClient.put(route, payload);
  }

  public UpdateAppointmentAssistanceStatus(
    assistanceStatusID: number,
    appointmentId: number,
    clinicId: number
  ) {
    const route =
      this.GET_BASE_URL() +
      `Assistance/UpdateAppointmentAssistanceStatus?assistanceStatusID=${assistanceStatusID}&appointmentId=${appointmentId}&clinicId=${clinicId}`;
    return this.httpClient.put(route, {});
  }

  public GetTcmDetail(clinicID: number, appointmentid: number) {
    return this.httpClient.get<TCMDetail>(
      this.GET_BASE_URL() +
        `TC/TransitionalCareRecords/GetTcmDetail?clinicID=${clinicID}&appointmentid=${appointmentid}`
    );
  }
  public SendSms(data: {
    AppointmentId: number;
    ToNumber: string;
    MessageContent: string;
  }) {
    return this.httpClient.post(this.GET_BASE_URL() + 'SMS/sendmessage', data);
  }

  public VA_GetAssociatedAppointment(callId: number, clinicID: number) {
    const route =
      this.GET_BASE_URL() +
      `VA/${clinicID}/IncomingCalls/GetAssociatedAppointment?callId=${callId}`;

    return this.httpClient.get(route);
  }

  public IsUserPrinter(clinicID: number) {
    const route =
      this.GET_BASE_URL() +
      `ClinicPermissions/IsUserPrinter?ClinicID=${clinicID}`;

    return this.httpClient.get(route);
  }

  public GetReminderSchedulesConfig(clinicid: number, appointmentDate: Date) {
    const route =
      this.GET_BASE_URL() +
      `ReminderSchedule/GetReminderSchedulesConfig?ClinicID=${clinicid}&appointmentDate=${appointmentDate.toISOString()}`;

    return this.httpClient.get<GetReminderSchedulesConfigResult[]>(route);
  }

  public GetTCMGraphsData(
    clinicID: number,
    fromDate: Date,
    toDate: Date,
    searchTerm: string
  ) {
    const route =
      this.GET_BASE_URL() +
      `TC/TransitionalCareRecords/GetTCMGraphsData?clinicID=${clinicID}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}${
        searchTerm ? `&searchTerm=${searchTerm}` : ''
      }`;

    return this.httpClient.get<
      Array<{
        appointmentTypeId: number;
        data: Array<{
          date: Date;
          count: number;
        }>;
      }>
    >(route);
  }

  public GetGoogleConnection(doctorID: number) {
    const route =
      this.GET_BASE_URL() +
      `api/GoogleConnection/GetGoogleConnectionForDoctor?doctorId=${doctorID}`;

    return this.httpClient.get<GoogleTokenId>(route);
  }

  public DisconnectGoogleConnection(token: GoogleTokenId) {
    const route =
      this.GET_BASE_URL() +
      `api/googleconnection/disconnect-google-calendar?GoogleTokenId=${token.googleTokenId}`;

    return this.httpClient.post(route, null);
  }

  public GetCallTimingSprocs() {
    const route = this.GET_BASE_URL() + `CallTimingSproc/GetCallTimingSprocs`;
    return this.httpClient.get(route);
  }

  public GetCallTimingSproc(sprocID: number) {
    return this.httpClient.get<GetCallTimingSprocDTO>(
      this.GET_BASE_URL() +
        'CallTimingSproc/GetCallTimingSproc?sprocID=' +
        sprocID
    );
  }

  public SearchCallTimingSproc(searchTerm: string) {
    const route =
      this.GET_BASE_URL() +
      `CallTimingSproc/SearchCallTimingSproc?searchTerm=${searchTerm}`;
    return this.httpClient.get(route);
  }

  public CreateCallTimingSproc(sproc: CallTimingSprocDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'CallTimingSproc/CreateCallTimingSproc',
      sproc
    );
  }

  public UpdateCallTimingSproc(sproc: CallTimingSprocDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'CallTimingSproc/UpdateCallTimingSproc',
      sproc
    );
  }

  public GetVirtualAssistants(clinicID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `VA/${clinicID}/config/GetList`
    );
  }

  public GetInsurancePlan(insurancePlanID: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `InsurancePlans/GetInsurancePlan?insurancePlanID=${insurancePlanID}`
    );
  }

  public GetInsurancePlans() {
    const route = this.GET_BASE_URL() + `InsurancePlans/GetAllInsurancePlans`;
    return this.httpClient.get(route);
  }

  public CreateInsurancePlan(insurancePlan: InsurancePlansDTO) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'InsurancePlans/CreateInsurancePlan',
      insurancePlan
    );
  }

  public UpdateInsurancePlan(insurancePlan: InsurancePlansDTO) {
    return this.httpClient.put(
      this.GET_BASE_URL() + 'InsurancePlans/UpdateInsurancePlan',
      insurancePlan
    );
  }

  public GetPatients(
    currentPage: number,
    pageSize: number,
    clinicID: number,
    searchTearm: string
  ) {
    const route = `api/PM/PatientRecords/GetPagedList?page=${currentPage}&pageSize=${pageSize}&clinicId=${clinicID}&searchTerm=${searchTearm}`;
    return this.httpClient.get(this.GET_BASE_URL() + route);
  }

  public GetPatient(patientId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/PM/PatientRecords/${patientId}`
    );
  }

  public CreatePatient(patient: PatientDetail) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'api/PM/PatientRecords',
      patient
    );
  }

  public UpdatePatient(patient: PatientDetail) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `api/PM/PatientRecords/${patient.patientRecordId}`,
      patient
    );
  }

  public GetPhoneNumbers(patientId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/PM/PhoneNumbers?PatientRecordId=${patientId}`
    );
  }

  public CreatePhoneNumber(phoneNumber: PatientPhoneNumber) {
    return this.httpClient.post(
      this.GET_BASE_URL() + 'api/PM/PhoneNumbers',
      phoneNumber
    );
  }

  public UpdatePhoneNumbers(phoneList: PatientPhoneNumber[]) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `api/PM/PhoneNumbers/UpdateAll`,
      phoneList
    );
  }

  public DeletePhoneNumber(phoneNumberId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + 'api/PM/PhoneNumbers/' + phoneNumberId
    );
  }

  public GetBookingServices(clinicId: number) {
    return this.httpClient.get<BookingService[]>(
      this.GET_BASE_URL() + `api/bookings/BookingServices?clinicId=${clinicId}`
    );
  }

  public CreateBookingService(bookingService: BookingService) {
    return this.httpClient.post<BookingService>(
      this.GET_BASE_URL() + 'api/bookings/BookingServices',
      bookingService
    );
  }

  public UpdateBookingService(bookingService: BookingService) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/BookingServices/${bookingService.bookingServiceId}`,
      bookingService
    );
  }

  public DeleteBookingService(bookingService: BookingService) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'api/bookings/BookingServices/' +
        bookingService.bookingServiceId
    );
  }

  public GetBookingServiceDoctors(clinicId: number, bookingServiceId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `api/bookings/BookingServiceDoctors?clinicId=${clinicId}&bookingServiceId=${bookingServiceId}`
    );
  }

  public GetBookingServiceDoctor(bookingServiceDoctorId: number) {
    return this.httpClient.get(
      this.GET_BASE_URL() +
        `api/bookings/BookingServiceDoctors/` +
        bookingServiceDoctorId
    );
  }

  public CreateBookingServiceDoctor(bookingServiceDoctor: Doctor) {
    return this.httpClient.post<BookingService>(
      this.GET_BASE_URL() + 'api/bookings/BookingServiceDoctors/',
      bookingServiceDoctor
    );
  }

  public UpdateBookingServiceDoctor(bookingServiceDoctor: Doctor) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/bookings/BookingServiceDoctors/${bookingServiceDoctor.doctorId}`,
      bookingServiceDoctor
    );
  }

  public DeleteBookingServiceDoctor(bookingServiceDoctorId: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        'api/bookings/BookingServiceDoctors/' +
        bookingServiceDoctorId
    );
  }

  public GetBookingAppointments(
    clinicID: number,
    page: number,
    pageSize: number,
    patientRecordId: number[],
    from?: Date,
    to?: Date,
    bookingServiceId?: number[],
    bookingStatusId?: number[],
    doctorId?: number[]
  ) {
    const payload: GetBookingsRequest = {
      clinicID: clinicID,
      pageIndex: page - 1 || 0,
      pageSize: pageSize,
      from: from,
      to: to,
      patientRecordsIds: (patientRecordId || []).filter((v) => v),
      bookingServicesId: (bookingServiceId || []).filter((v) => v),
      bookingStatusesId: (bookingStatusId || []).filter((v) => v),
      doctorsId: (doctorId || []).filter((v) => v),
    };

    let route = `api/bookings/GetBookingsList`;
    return this.httpClient.post<BookingAppointmentsList>(
      this.GET_BASE_URL() + route,
      payload
    );
  }

  public GetBookingStatuses() {
    return this.httpClient.get(
      this.GET_BASE_URL() + `api/bookings/BookingStatus/`
    );
  }

  public CreateBooking(booking: BookingModel) {
    return this.httpClient.post(this.GET_BASE_URL() + 'api/bookings/', booking);
  }

  public UpdateBooking(booking: BookingModel) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `api/bookings/${booking.bookingID}`,
      booking
    );
  }
  public GetBooking(clinicID: number, bookingId: number) {
    return this.httpClient.get<BookingModel>(
      this.GET_BASE_URL() + `api/bookings/${bookingId}?clinicID=${clinicID}`
    );
  }
  public CreateApiUser(newApiUser: ApiUserDTO) {
    return this.httpClient.post<ApiUserDTO>(
      this.GET_BASE_URL() + 'ApiUsers/',
      newApiUser
    );
  }
  public UpdateApiUser(apiUserUpdate: ApiUserDTO) {
    return this.httpClient.put<ApiUserDTO>(
      this.GET_BASE_URL() + `ApiUsers/${apiUserUpdate.apiUserId}`,
      apiUserUpdate
    );
  }
  public GetApiUser(apiUserId: number) {
    return this.httpClient.get(this.GET_BASE_URL() + `ApiUsers/${apiUserId}`);
  }
  public GrantAccessToClinic(ApiUserId: number, clinicid: number) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `ApiUsers/GrantClinicAccess?ApiUserId=${ApiUserId}&clinicid=${clinicid}`,
      clinicid
    );
  }
  public RevokeAccessToClinic(ApiUserId: number, clinicid: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() +
        `ApiUsers/RevokeClinicAccess?ApiUserId=${ApiUserId}&clinicid=${clinicid}`
    );
  }
  public GetAssociates() {
    return this.httpClient.get(this.GET_BASE_URL() + `Associate/GetAll`);
  }

  public GetApiUsers(
    currentPage: number,
    pageSize: number,
    clinicID: number,
    searchTerm: string
  ) {
    const route = `ApiUsers/GetPagedList?page=${currentPage}&pageSize=${pageSize}
      ${clinicID ? `&clinicId=${clinicID}` : ''}
      ${searchTerm ? `&searchTerm=${searchTerm}` : ''}`;
    return this.httpClient.get<IApiUserList>(this.GET_BASE_URL() + route);
  }

  public GetClinics(searchTerm?: string, clinicId?: number) {
    const queryParams = {
      ...(searchTerm ? { searchTerm } : {}),
      ...(clinicId ? { clinicId } : {}),
    };
    return this.httpClient.get<AdminViewAsClinicDTO[]>(
      this.GET_BASE_URL() +
        `Clinics/GetClinics?${new URLSearchParams(
          queryParams as any
        ).toString()}`
    );
  }

  public GetAccountWithClinicInfo(clinicId: number) {
    return this.httpClient.get<GetAccountWithClinicInfo>(
      this.GET_BASE_URL() + `VA/${clinicId}/config/GetAccountWithClinicInfo`
    );
  }

  public getGoogleCalendars(clinicId: number, doctorId: number) {
    return this.httpClient.get<GoogleCalendarDto[]>(
      this.GET_BASE_URL() +
        `api/GoogleConnection/get-google-calendars?doctorId=${doctorId}&clinicId=${clinicId}`
    );
  }

  public EnableCalendarSync(
    clinicId: number,
    doctorId: number,
    calendar: {
      googleTokenId: number;
      googleCalendarId: string;
      description: string;
      summary: string;
    }
  ) {
    return this.httpClient.post(
      this.GET_BASE_URL() +
        `api/GoogleConnection/enable-calendar-sync?doctorId=${doctorId}&clinicId=${clinicId}`,
      calendar
    );
  }

  public DisableCalendarSync(
    clinicId: number,
    doctorId: number,
    googleConnectedCalendarId: number
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/GoogleConnection/disable-calendar-sync?doctorId=${doctorId}&clinicId=${clinicId}&GoogleConnectedCalendarId=${googleConnectedCalendarId}`,
      {}
    );
  }

  public TogglePrimaryCalendarSync(
    clinicId: number,
    doctorId: number,
    GoogleTokenId: number
  ) {
    return this.httpClient.put(
      this.GET_BASE_URL() +
        `api/GoogleConnection/toggle-primary-calendar-sync?doctorId=${doctorId}&clinicId=${clinicId}&GoogleTokenId=${GoogleTokenId}`,
      {}
    );
  }

  public CreateWhiteListedPhone(whiteListedPhone: WhiteListedPhoneNumbers) {
    return this.httpClient.post<WhiteListedPhoneNumbers>(
      this.GET_BASE_URL() + 'WhiteListedPhoneNumber/AddPhone/',
      whiteListedPhone
    );
  }

  public GetAllWhiteListClinic(
    clinicID: number,
    page: number,
    pageSize: number,
    searchTerm: string
  ) {
    const route = `WhiteListedPhoneNumber/GetAllWhiteListClinic?clinicId=${clinicID}&page=${page}&pageSize=${pageSize}${
      searchTerm ? `&searchTerm=${searchTerm}` : ''
    }`;
    return this.httpClient.get<WhiteListedPhoneNumbersList>(
      this.GET_BASE_URL() + route
    );
  }

  public DeleteWhiteListedPhone(whiteListedPhoneNumberId: number) {
    return this.httpClient.delete<WhiteListedPhoneNumbersList>(
      this.GET_BASE_URL() +
        'WhiteListedPhoneNumber/DeletePhoneNumber?WhiteListedPhoneNumberId=' +
        whiteListedPhoneNumberId
    );
  }

  public GetEngineSettings(
    currentPage: number,
    pageSize: number,
    searchTerm: string
  ) {
    const route = `Constants/GetListConstants?page=${currentPage}&pageSize=${pageSize}      
      ${searchTerm ? `&searchTerm=${searchTerm}` : ''}`;
    return this.httpClient.get<IEngineSettingsList>(
      this.GET_BASE_URL() + route
    );
  }
  public CreateConstant(EngineSetting: EngineSetting) {
    return this.httpClient.post(
      this.GET_BASE_URL() + `Constants/CreateConstant`,
      EngineSetting
    );
  }
  public UpdateConstant(EngineSetting: EngineSetting) {
    return this.httpClient.put(
      this.GET_BASE_URL() + `Constants/UpdateConstant`,
      EngineSetting
    );
  }
  public DeleteConstant(constantID: number) {
    return this.httpClient.delete(
      this.GET_BASE_URL() + `Constants/DeleteConstant?ConstantID=${constantID}`
    );
  }
}

export interface GetReminderSchedulesConfigResult {
  reminderScheduleId: number;
  doctorId: number;
  reminderSetName: string;
  doctorName: string;
  callTypeId: number;
  callTypeName: string;
  callTimingSetId: number;
  callTimingSetName: string;
  description: string;
  callAttempts: number;
  forAppointmentOn: string;
  callOn: string;
  callDate: string;
}
