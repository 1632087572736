import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { InsurancePlansDTO } from 'src/app/DTOS/InsurancePlans/InsurancePlansDTO';
import {
  PatientDetail,
  PatientPhoneNumber,
} from 'src/app/DTOS/Patient/Patient-Detail';

interface Gender {
  value: number;
  viewValue: string;
}

interface PhoneTypes {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router
  ) {}

  @ViewChild('patientForm', { static: false }) public patientForm: NgForm;
  @ViewChild('contactForm', { static: false }) public contactForm: NgForm;

  public isLoading = false;
  public isEditing = false;
  public isEditingContact = false;
  public patient: PatientDetail = {} as any;
  public phoneNumber: PatientPhoneNumber = {} as any;
  public backup_patient: PatientDetail = {} as any;
  public backup_phoneNumber: PatientPhoneNumber = {} as any;
  public InsurancePlans: InsurancePlansDTO[];
  public User: User;
  public patientID: number;
  genders: Gender[] = [
    { value: 1, viewValue: 'Masculino' },
    { value: 2, viewValue: 'Femenino' },
    { value: 3, viewValue: 'Otro' },
  ];
  phoneTypes: PhoneTypes[] = [
    { value: 1, viewValue: 'Movil' },
    { value: 2, viewValue: 'Residencial' },
    { value: 3, viewValue: 'Trabajo' },
    { value: 4, viewValue: 'Alternativo' },
  ];
  phoneList: PatientPhoneNumber[] = [];

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });

    this.route.params.subscribe((params) => {
      const patientid = params.patient_id;
      if (!patientid) {
        this.isEditing = true;
      } else {
        this.patientID = patientid;
        this.fetchPatient(patientid);
        this.FetchPhoneNumbers(patientid);
      }
    });

    this.FetchInsurancePlans();
  }

  public enableEdition = () => {
    this.isEditing = true;
    this.backup_patient = _.cloneDeep(this.patient);
  };

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.patient = _.cloneDeep(this.backup_patient);
    this.backup_patient = {} as any;
  };

  public enableContactEdition = () => {
    this.isEditingContact = true;
    this.backup_phoneNumber = _.cloneDeep(this.phoneNumber);
  };

  public discardContactEditionChanges = () => {
    this.isEditingContact = false;
    this.phoneNumber = _.cloneDeep(this.backup_phoneNumber);
    this.backup_phoneNumber = {} as any;
  };

  public save = () => {
    this.isEditing = false;
    this.isLoading = true;
    if (this.patient?.patientRecordId) {
      this.doUpdate();
    } else {
      this.patient.clinicId = this.User.clinicId;
      this.doCreate();
    }
  };

  public saveContact = () => {
    this.isEditingContact = false;
    this.isLoading = true;

    for (const phone of this.phoneList) {
      if (phone.phoneNumberId === 0) {
        this.doCreateContact(phone);
      } else {
        this.doUpdateContact();
      }
    }
  };

  doCreate() {
    this.patient.phoneNumbers = this.phoneList;
    this.API.CreatePatient(this.patient).subscribe(
      (r: { patientID: number }) => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, el paciente ${this.patient.firstName} ha sido creado. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.router.navigate(['/Paciente', r.patientID]);
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  doUpdate() {
    this.API.UpdatePatient(this.patient).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, el paciente ${this.patient.firstName} ha sido actualizado. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public FetchInsurancePlans() {
    this.isLoading = true;
    this.API.GetInsurancePlans().subscribe(
      (r) => {
        this.InsurancePlans = r as InsurancePlansDTO[];
        this.isLoading = false;
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public fetchPatient = (patientId: number) => {
    this.isLoading = true;
    this.API.GetPatient(patientId).subscribe(
      (r) => {
        this.patient = r as PatientDetail;
        this.isLoading = false;
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  };

  public FetchPhoneNumbers(patientId: number) {
    this.API.GetPhoneNumbers(patientId).subscribe(
      (r) => {
        this.phoneList = r as PatientPhoneNumber[];
        this.isLoading = false;
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public addContact() {
    this.isEditingContact = true;
    const contact: PatientPhoneNumber = {} as any;
    contact.number = null;
    contact.type = 0;
    contact.isPrimary = false;
    contact.patientRecordId = this.patient.patientRecordId;
    contact.phoneNumberId = 0;
    this.phoneList.push(contact);
  }

  doCreateContact(phone: PatientPhoneNumber) {
    this.API.CreatePhoneNumber(phone).subscribe(
      (r) => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, el numero ${phone.number} ha sido creado. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.FetchPhoneNumbers(this.patientID);
      },
      (e) => {
        this.isLoading = false;
        this.isEditingContact = true;
        this.showError(e);
      }
    );
  }

  doUpdateContact() {
    this.API.UpdatePhoneNumbers(this.phoneList).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open(`¡Listo!, el número ha sido actualizado. `, 'OK ', {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.FetchPhoneNumbers(this.patientID);
      },
      (e) => {
        this.isLoading = false;
        this.isEditingContact = true;
        this.showError(e);
      }
    );
  }

  public deleteContactEntry(phoneNumberId: number) {
    this.API.DeletePhoneNumber(phoneNumberId).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open(`¡Listo!, el número ha sido eliminado. `, 'OK ', {
          duration: 70000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.FetchPhoneNumbers(this.patientID);
      },
      (e) => {
        this.isLoading = false;
        this.isEditingContact = true;
        this.showError(e);
      }
    );
  }

  public contactIsPrimary(event, phoneNumberId: number) {
    if (event.checked === true) {
      for (const phone of this.phoneList) {
        if (phone.phoneNumberId === phoneNumberId) {
          phone.isPrimary = true;
        } else {
          phone.isPrimary = false;
        }
      }
    } else {
      for (const phone of this.phoneList) {
        if (phone.phoneNumberId === phoneNumberId) {
          phone.isPrimary = false;
        }
      }
    }
  }
}
