import { AfterViewInit, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as moment from 'moment';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { AppointmentType } from '../../DTOS/Appointments-types/appointmentType';

import { User } from '../../_models';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-transitional-care-admisions-and-discharges',
  templateUrl: './transitional-care-admisions-and-discharges.component.html',
  styleUrls: ['./transitional-care-admisions-and-discharges.component.scss'],
})
export class TransitionalCareAdmisionsAndDischargesComponent
  implements AfterViewInit
{
  isLoading: boolean;
  public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public AppointmentType: number;
  public TransitionalCareHospitalizados: number =
    AppointmentType.TransitionalCareHospitalizados;
  public TransitionalCareAltasconCita: number =
    AppointmentType.TransitionalCareAltasconCita;
  public TransitionalCareAltassinCita: number =
    AppointmentType.TransitionalCareAltassinCita;
  from: Date = new Date();
  to: Date = new Date();
  public isImportSidebarOpen = false;
  public isImportSubmitting: boolean;
  public importingAppointmentType?: number;
  public tabIndexTransitionalCare: number;
  public clinicId: number;

  constructor(
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {
    this.AppointmentType = AppointmentType.TransitionalCareHospitalizados;
    this.tabIndexTransitionalCare = 0;
    this.barchartoptions = {
      plugins: {
        legend: {
          labels: {
            color: '#ebedef',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#ebedef',
          },
          grid: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
        y: {
          ticks: {
            color: '#ebedef',
          },
          grid: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
    };
    this.barChartData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: 'My Second dataset',
          backgroundColor: '#FFA726',
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };

    this.from = moment().startOf('week').toDate();
    this.to = moment().toDate();
  }
  barChartData: any;
  barchartoptions: any;
  async onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.tabIndexTransitionalCare = 0;
    await this.LoadResumenTransitionalCare();
  }
  async ngAfterViewInit() {
    this.isLoading = true;
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
      this.isLoading = false;
      this.clinicId = this.User?.clinicId;
      this.GetTransitionalCareHospitalized();
    });
  }

  async onTabChange(event: MatTabChangeEvent) {
    this.AppointmentType = this.TransitionalCareHospitalizados;
    this.tabIndexTransitionalCare = event.index;
  }

  async GetTransitionalCareHospitalized() {
    await this.LoadResumenTransitionalCare();
  }

  public onImportClicked(from: 'Hospitalizados' | 'ConCita' | 'sinCita') {
    this.isImportSidebarOpen = true;
    if (from === 'Hospitalizados') {
      this.importingAppointmentType =
        AppointmentType.TransitionalCareHospitalizados;
    }
    if (from === 'ConCita') {
      this.importingAppointmentType =
        AppointmentType.TransitionalCareAltasconCita;
    }
    if (from === 'sinCita') {
      this.importingAppointmentType =
        AppointmentType.TransitionalCareAltassinCita;
    }
  }

  async LoadResumenTransitionalCare() {}

  public uploadComplete() {
    const event: any = new MatTabChangeEvent();
    event.index = this.tabIndexTransitionalCare;
    this.importingAppointmentType = null;
    this.isImportSidebarOpen = false;
    this.LoadResumenTransitionalCare();
    this.onTabChange(event);
    this._snackBar.open(
      `¡Listo!, el archivo ha sido cargado correctamente. `,
      'OK ',
      {
        duration: 10000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      }
    );
  }

  public getTemplateUrl() {
    let fileName = '';

    if (
      this.importingAppointmentType ===
      AppointmentType.TransitionalCareHospitalizados
    ) {
      fileName = 'Plantilla_Hospitalizados.xlsx';
    } else if (
      this.importingAppointmentType ===
      AppointmentType.TransitionalCareAltasconCita
    ) {
      fileName = 'Plantilla_altas_con_cita.xlsx';
    } else if (
      this.importingAppointmentType ===
      AppointmentType.TransitionalCareAltassinCita
    ) {
      fileName = 'Plantilla_altas_sin_cita.xlsx';
    }

    return `/files/TC-Templates/` + fileName;
  }

  public getUrl() {
    let userId = this.User?.userId;
    let clinicid = this.User?.clinicId;

    if (this.ViewAs?.clinicId) {
      userId = this.ViewAs?.viewAsUserID;
      clinicid = this.ViewAs?.clinicId;
    }

    return (
      '/TC/TransitionalCareRecords/Import' +
      `?recordType=${
        this.importingAppointmentType || 0
      }&clinicId=${clinicid}&userId=${userId}`
    );
  }
}
