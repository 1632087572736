import {
  AuthGuard,
  AuthGuardAdmin,
  ErrorInterceptor,
  JwtInterceptor,
} from './_helpers';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  ImportAppointmentsDialogComponent,
  ImportResultDialogComponent,
} from './_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
} from '@angular-material-components/color-picker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

import { AccountComponent } from './Pages/va-account/va-account.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountStatsReportComponent } from './Pages/account-stats-report/account-stats-report.component';
import { AccountsComponentComponent } from './Pages/va-accounts/va-accounts.component';
import { AddAppointmentsComponent } from './Pages/add-appointments/add-appointments.component';
import { AddAppointmentsDialogComponent } from './_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { AddRemindersComponent } from './Pages/add-reminders/add-reminders.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AgendaComponent } from './agenda/agenda.component';
import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need
import { ApiUsersDetailsComponent } from './pages/api-users-details/api-users-details.component';
import { ApiUsersListComponent } from './Pages/api-users-list/api-users-list.component';
import { AppComponent } from './app-component/app.component';
import { AppointmentDetailsDialogComponent } from './_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import { AppointmentTypesComponent } from './Pages/appointment-types/appointment-types.component';
import { AppointmentsRequestListComponent } from './Pages/appointments-request-list/appointments-request-list.component';
import { AssistanceStatusConfigPageComponent } from './Pages/assistance-status-config-page/assistance-status-config-page.component';
import { BalanceDueComponent } from './_dialogs/balance-due/balance-due.component';
import { BookingsAgendaPageComponent } from './Pages/bookings-agenda-page/bookings-agenda-page.component';
import { BookingsAppointmentsTableComponent } from './Pages/bookings-appointments-table/bookings-appointments-table.component';
import { BookingsBookingFormComponent } from './Pages/bookings-booking-form/bookings-booking-form.component';
import { BookingsConfigurationComponent } from './Pages/bookings-configuration/bookings-configuration.component';
import { BookingsConfigurationDialogComponent } from './_dialogs/bookings-configuration-dialog/bookings-configuration-dialog.component';
import { BookingsDialogComponent } from './_dialogs/bookings-dialog/bookings-dialog.component';
import { BookingsDoctorsComponent } from './Pages/bookings-doctors/bookings-doctors.component';
import { BookingsNewBookingComponent } from './Pages/bookings-new-booking/bookings-new-booking.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BulkMessageCreateComponent } from './Pages/bulk-message-create/bulk-message-create.component';
import { CSVImportDialogComponent } from './_dialogs/csv-import-dialog/csv-import-dialog';
import { CalendarModule } from 'primeng/calendar';
import { CallAgainSchedulesComponent } from './Pages/call-again-schedules/call-again-schedules.component';
import { CallTimingSprocsComponent } from './Pages/call-timing-sprocs/call-timing-sprocs.component';
import { CallTimingsComponent } from './Pages/call-timings/call-timings.component';
import { ChatComponent } from './Pages/chat/chat.component';
import { CitamedFooterComponent } from './citamed-footer/citamed-footer.component';
import { ClinicAppointmentTypesComponent } from './Pages/clinic-appointment-types/clinic-appointment-types.component';
import { ClinicComponent } from './Pages/clinic/clinic.component';
import { ClinicDetailsComponent } from './Pages/clinic-details/clinic-details.component';
import { ClinicNewWhitelistedPhoneDialogComponent } from './_dialogs/clinic-new-whitelisted-phone-dialog/clinic-new-whitelisted-phone-dialog.component';
import { ClinicPermissionsComponent } from './Pages/clinic-permissions/clinic-permissions.component';
import { ClinicTransitionalCareHospitalizecTabComponent } from './Pages/clinic-transitional-care-hospitalizec-tab/clinic-transitional-care-hospitalizec-tab.component';
import { ClinicTransitionalCareWithAppointmentTabComponent } from './Pages/clinic-transitional-care-with-appointment-tab/clinic-transitional-care-with-appointment-tab.component';
import { ClinicTransitionalCareWithoutAppointmentTabComponent } from './Pages/clinic-transitional-care-without-appointment-tab/clinic-transitional-care-without-appointment-tab.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CompanyComponent } from './Pages/company/company.component';
import { CompanyDetailsComponent } from './pages/company-details/company-details.component';
import { CompanyDialogComponent } from './_dialogs/company-dialog/company-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { DateSelectionRibbonComponent } from './date-selection-ribbon/date-selection-ribbon.component';
import { DigitalWaitingListComponent } from './Pages/digital-waiting-list/digital-waiting-list.component';
import { DoctorAssistanceComponent } from './Pages/doctor-assistance/doctor-assistance.component';
import { DoctorDialogComponent } from './_dialogs/doctor-dialog/doctor-dialog.component';
import { DoctorsListComponent } from './Pages/doctors-list/doctors-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropdownModule } from 'primeng/dropdown';
import { EhrsComponent } from './Pages/ehrs/ehrs.component';
import { EngineSettingsComponent } from './pages/engine-settings/engine-settings.component';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './Pages/home/home.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { InsurancePlanDialogComponent } from './_dialogs/insurance-plan-dialog/insurance-plan-dialog.component';
import { InsurancePlansComponent } from './Pages/insurance-plans/insurance-plans.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { LoginComponent } from './Pages/login/login.component';
import { MasterUserViewAsSelectorComponent } from './master-user-view-as-selector/master-user-view-as-selector.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MissedCallsComponent } from './Pages/missed-calls/missed-calls.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NoAppointmentsAnimation } from './Pages/no-appointments-animation/NoAppointmentsAnimation.component';
import { NoCallDateComponent } from './Pages/no-call-date/no-call-date.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { PasswordRecoverComponent } from './pages/password-recover/password-recover.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { PatientDetailsComponent } from './Pages/patient-details/patient-details.component';
import { PatientListComponent } from './Pages/patient-list/patient-list.component';
import { PermissionComponent } from './Pages/permission/permission.component';
import { PermissionDialogComponent } from './_dialogs/permission-dialog/permission-dialog.component';
import { PermissionSlugsService } from './Services/PermissionSlugsService';
import { RatePlansComponent } from './Pages/rate-plans/rate-plans.component';
import { ReminderScheduleComponent } from './pages/reminder-schedule/reminder-schedule.component';
import { RemindersComponent } from './pages/reminders/reminders.component';
import { RemindersetRemindersetComponent } from './pages/reminderset-reminderset/reminderset-reminderset.component';
import { ReportsViewerComponent } from './_dialogs/reports-viewer/reports-viewer.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from './_services/SafePipe ';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarModule } from 'primeng/sidebar';
import { SpecialAnnouncementComponent } from './pages/special-announcement/special-announcement.component';
import { SupportChannelsDialogComponent } from './_dialogs/support-channels-dialog/support-channels-dialog.component';
import { SystemMessagesComponent } from './system-messages/system-messages.component';
import { TableModule } from 'primeng/table';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TransitionalCareAdmisionsAndDischargesComponent } from './Pages/transitional-care-admisions-and-discharges/transitional-care-admisions-and-discharges.component';
import { TransitionalCareResumenTabComponent } from './transitional-care-resumen-tab/transitional-care-resumen-tab.component';
import { TransitionalCareTableComponent } from './Pages/transitional-care-table/transitional-care-table.component';
import { TwilioCallerIdActivatorComponent } from './Pages/twilio-caller-id-activator/twilio-caller-id-activator.component';
import { UnauthorizedMessageComponent } from './Pages/unauthorizedMessage/unauthorizedMessage.component';
import { UpdateAppointmentCallStatusDialogComponent } from './_dialogs/update-appointment-call-status/update-appointment-call-status.component';
import { UpdateAppointmentDialogComponent } from './_dialogs/update-appointment/update-appointment.component';
import { UsersComponent } from './users/users.component';
import { VaConfigurationComponent } from './Pages/va-configuration/va-configuration.component';
import { VaDoctorTagsComponent } from './Pages/va-doctor-tags/va-doctor-tags.component';
import { VaDoctorsComponent } from './Pages/va-doctors/va-doctors.component';
import { VaServiceTagsComponent } from './Pages/va-service-tags/va-service-tags.component';
import { VaServicesComponent } from './Pages/va-services/va-services.component';

// import { CallTimingSprocsDetailComponent } from './Pages/call-timing-sprocs-detail/call-timing-sprocs-detail.component';











// import { CodeEditorModule } from '@ngstack/code-editor';








































































































// defineFullCalendarElement();
// import { FullCalendarModule } from '@fullcalendar/angular';

// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import listPlugin from '@fullcalendar/list';
// import timeGridPlugin from '@fullcalendar/timegrid';

// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   listPlugin,
//   interactionPlugin,
// ]);
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LT',
  },
  display: {
    dateInput: 'YYYY-MM-DD,LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PasswordRecoveryComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    PasswordRecoverComponent,
    HomeComponent,
    AddAppointmentsComponent,
    NoCallDateComponent,
    UsersComponent,
    SpecialAnnouncementComponent,
    LoginComponent,
    MasterUserViewAsSelectorComponent,
    CitamedFooterComponent,
    AddUserComponent,
    UpdateAppointmentCallStatusDialogComponent,
    UpdateAppointmentDialogComponent,
    DoctorsListComponent,
    AccountDetailsComponent,
    AddAppointmentsDialogComponent,
    ReportsViewerComponent,
    RemindersComponent,
    SafePipe,
    ClinicComponent,
    CompanyComponent,
    AddRemindersComponent,
    CompanyDialogComponent,
    ClinicAppointmentTypesComponent,
    RemindersetRemindersetComponent,
    SupportChannelsDialogComponent,
    ReminderScheduleComponent,
    AppointmentDetailsDialogComponent,
    BalanceDueComponent,
    SystemMessagesComponent,
    DigitalWaitingListComponent,
    ChatComponent,
    EhrsComponent,
    CallTimingsComponent,
    RatePlansComponent,
    AppointmentTypesComponent,
    TwilioCallerIdActivatorComponent,
    ClinicDetailsComponent,
    CallAgainSchedulesComponent,
    AccountStatsReportComponent,
    MissedCallsComponent,
    AppointmentsRequestListComponent,
    PermissionComponent,
    PermissionDialogComponent,
    DoctorDialogComponent,
    VaConfigurationComponent,
    AccountComponent,
    VaDoctorsComponent,
    VaServicesComponent,
    ClinicPermissionsComponent,
    VaDoctorTagsComponent,
    VaServiceTagsComponent,
    BulkMessageCreateComponent,
    UnauthorizedMessageComponent,
    TransitionalCareAdmisionsAndDischargesComponent,
    TransitionalCareTableComponent,
    TransitionalCareResumenTabComponent,
    ClinicTransitionalCareHospitalizecTabComponent,
    ClinicTransitionalCareWithAppointmentTabComponent,
    ClinicTransitionalCareWithoutAppointmentTabComponent,
    DoctorAssistanceComponent,
    AssistanceStatusConfigPageComponent,
    ImportAppointmentsDialogComponent,
    ImportResultDialogComponent,
    CSVImportDialogComponent,
    CallTimingSprocsComponent,
    // CallTimingSprocsDetailComponent,
    AccountsComponentComponent,
    InsurancePlansComponent,
    InsurancePlanDialogComponent,
    PatientDetailsComponent,
    PatientListComponent,
    ApiUsersListComponent,
    BookingsConfigurationComponent,
    BookingsAgendaPageComponent,
    AgendaComponent,
    DateSelectionRibbonComponent,
    BookingsConfigurationDialogComponent,
    BookingsDoctorsComponent,
    BookingsAppointmentsTableComponent,
    BookingsBookingFormComponent,
    BookingsNewBookingComponent,
    BookingsDialogComponent,
    ApiUsersDetailsComponent,
    ClinicNewWhitelistedPhoneDialogComponent,
    NoAppointmentsAnimation,
    CompanyDetailsComponent,
    EngineSettingsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    MatSnackBarModule,
    MatExpansionModule,
    ConfirmPopupModule,
    MatBadgeModule,
    MatInputModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    NgChartsModule,
    MatSlideToggleModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TextFieldModule,
    FormsModule,
    MatSidenavModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatStepperModule,
    // https://github.com/casni14/amazing-time-picker
    AmazingTimePickerModule,
    // MaterialTimeControlModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    // FullCalendarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    MatGridListModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    DragDropModule,
    MatSidenavModule,
    NgxMatColorPickerModule,
    SidebarModule,
    PasswordModule,
    TableModule,
    OverlayPanelModule,
    InputNumberModule,
    FieldsetModule,
    MatDialogModule,
    DropdownModule,
    ColorPickerModule,
    MatChipsModule,
    FileUploadModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    MatRippleModule,
    NgxCsvParserModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    // CodeEditorModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: '',
          component: HomeComponent,
          pathMatch: 'full',
          canActivate: [AuthGuard],
        },
        {
          path: 'admin-view-as/:clinicID',
          component: HomeComponent,
          canActivate: [AuthGuardAdmin],
        },
        { path: 'login', component: LoginComponent },
        {
          path: 'RecoverPassword',
          component: PasswordRecoveryComponent,
        },
        {
          path: 'ResetPassword',
          component: PasswordRecoverComponent,
        },
        {
          path: 'add-appointments',
          component: AddAppointmentsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'no-call-dates-add-new',
          component: NoCallDateComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'special-Announcement',
          component: SpecialAnnouncementComponent,
          canActivate: [AuthGuard],
        },
        { path: 'Users', component: UsersComponent, canActivate: [AuthGuard] },
        {
          path: 'Add-User',
          component: AddUserComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'Doctors-list',
          component: DoctorsListComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'my-clinic-appointment-types',
          component: ClinicAppointmentTypesComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'account-details',
          component: AccountDetailsComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'waiting-list',
          component: DigitalWaitingListComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'reminders',
          component: RemindersComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'add-reminders',
          component: AddRemindersComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'company',
          component: CompanyComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'clinic',
          component: ClinicComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'reminder-schedules',
          component: ReminderScheduleComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'reminderSet-reminderSet',
          component: RemindersetRemindersetComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'ehrs',
          component: EhrsComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'rate-plans',
          component: RatePlansComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'calltimings',
          component: CallTimingsComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'appointment-types',
          component: AppointmentTypesComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'twilio-caller-id-activator',
          component: TwilioCallerIdActivatorComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'call-again-schedules',
          component: CallAgainSchedulesComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'clinic/:clinicId',
          component: ClinicDetailsComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'admin/clinic/New',
          component: ClinicDetailsComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'accounts-stats',
          component: AccountStatsReportComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'VA/accounts',
          component: AccountsComponentComponent,
        },
        {
          path: 'asistente-virtual/llamadas-perdidas',
          component: MissedCallsComponent,
        },
        {
          path: 'solicitudes-de-citas',
          component: AppointmentsRequestListComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'permisos',
          component: PermissionComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'asistente-virtual/Configuracion',
          component: VaConfigurationComponent,
        },
        {
          path: 'asistente-virtual/Configuracion/:accountID',
          component: VaConfigurationComponent,
        },
        {
          path: 'campañas/crear',
          component: BulkMessageCreateComponent,
        },
        {
          path: 'Transitional-Care-admisions-and-discharges',
          component: TransitionalCareAdmisionsAndDischargesComponent,
        },
        {
          path: 'Estatus-de-asistencia',
          component: AssistanceStatusConfigPageComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'Sprocs',
          component: CallTimingSprocsComponent,
        },
        // {
        //   path: 'Sproc',
        //   component: CallTimingSprocsDetailComponent,
        // },
        // {
        //   path: 'Sproc/:sproc_id',
        //   component: CallTimingSprocsDetailComponent,
        // },
        {
          path: 'Pacientes',
          component: PatientListComponent,
        },
        {
          path: 'Paciente/Nuevo',
          component: PatientDetailsComponent,
        },
        {
          path: 'Paciente/:patient_id',
          component: PatientDetailsComponent,
        },
        {
          path: 'planes-de-seguros',
          component: InsurancePlansComponent,
        },
        {
          path: 'agendamiento/configuracion',
          component: BookingsConfigurationComponent,
        },
        {
          path: 'Agendamiento/Nuevo',
          component: BookingsNewBookingComponent,
        },
        {
          path: 'api-users',
          component: ApiUsersListComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'agendamiento/configuracion',
          component: BookingsConfigurationComponent,
        },
        {
          path: 'agendamiento',
          component: BookingsAgendaPageComponent,
        },
        {
          path: 'company/:companyId',
          component: CompanyDetailsComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'admin/company/New',
          component: CompanyDetailsComponent,
          canActivate: [AuthGuardAdmin],
        },
        {
          path: 'engine-setting',
          component: EngineSettingsComponent,
          canActivate: [AuthGuardAdmin],
        },
        { path: '**', redirectTo: '', pathMatch: 'full' },
      ],
      { relativeLinkResolution: 'legacy' }
    ),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
  ],
  providers: [
    MatNativeDateModule,
    CookieService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    PermissionSlugsService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
