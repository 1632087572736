import * as moment from 'moment';

import {
  ApiService,
  GetReminderSchedulesConfigResult,
} from 'src/app/api.service';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  PatientDetail,
  PatientList,
} from 'src/app/DTOS/Patient/Patient-Detail';

import { AddAppointmentDto } from 'src/app/dtos/Appointments/AppointmentDTO';
import { AuthenticationService } from 'src/app/_services';
import { BookingModel } from 'src/app/DTOS/Bookings/BookingDTO';
import { BookingService } from 'src/app/DTOS/Bookings/BookingServiceDTO';
import { Doctor } from 'src/app/Dtos/Doctors/DoctorDTO';
import { GetBookingStatus } from 'src/app/DTOS/Bookings/BookingAppointmentsDTO';
import { Howl } from 'howler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { ReminderSetDto } from 'src/app/dtos/ReminderSets/ReminderSetDto';
import { User } from 'src/app/_models';
import { deepCopy } from 'src/app/helpers/DeepCopy';

type AppointmentConfigMode = 'MANUAL' | 'AUTO';

@Component({
  selector: 'app-bookings-booking-form',
  templateUrl: './bookings-booking-form.component.html',
  styleUrls: ['./bookings-booking-form.component.scss'],
})
export class BookingsBookingFormComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private injector: Injector
  ) {
    this.dataFromMatDialogEvent = this.injector.get(MAT_DIALOG_DATA, null);
    if (this.dataFromMatDialogEvent) {
      this.booking = this.dataFromMatDialogEvent;
    }
  }

  @ViewChild('bookingForm', { static: false }) public bookingForm: NgForm;
  @Input() public booking: BookingModel = {
    startDateTimeUTC: undefined,
    endDateTimeUTC: undefined,
    durationInMinutes: 0,
    appointmentId: null,
    bookingServiceId: 0,
    bookingStatusId: 0,
    patientRecordId: 0,
    doctorId: 0,
    clinicId: 0,
    isRecurring: false,
    notes: '',
  };

  public isSubmitting: Boolean = false;
  public isLoading: Boolean = false;
  public isLoadingServices: Boolean = false;
  public isLoadingStatuses: Boolean = false;
  public isLoadingDoctors: Boolean = false;
  public isLoadingPatients: Boolean = false;
  public isEditing: Boolean = false;
  public Services: BookingService[];
  public Statuses: GetBookingStatus[];
  public Doctors: Doctor[];
  public Patients: PatientDetail[];
  public User: User;
  public clinicId: number;
  public pageSize = 10;
  public currentPage = 1;
  public searchTerm = '';
  public _unFiltered_Doctors: Doctor[];
  public _unFiltered_Services: BookingService[];
  public _unFiltered_Statuses: GetBookingStatus[];
  public dataFromMatDialogEvent: BookingModel;
  selectedReminderSet: ReminderSetDto;
  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  public _unFiltered_ReminderSets: ReminderSetDto[] =
    new Array<ReminderSetDto>();
  appointmentConfigMode: AppointmentConfigMode = null;
  isPlayingAudio = false;
  current_Audio_Hawl: any;
  AppointmentsCallDatePicker;
  appointmentData: AddAppointmentDto;
  getReminderSchedulesConfigResults: GetReminderSchedulesConfigResult[];
  public isLoadingReminderSets = false;
  @ViewChild('AppointmentsCallDatePicker')
  public callAttempts: number;

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.loadData();
    });
  }

  public async loadData() {
    this.clinicId =
      this.booking.clinicId == null
        ? this.User.clinicId
        : this.booking.clinicId;
    await Promise.all([
      this.FetchServices(),
      this.FetchStatuses(),
      this.FetchDoctors(),
      this.FetchPatients(),
    ]);
  }

  public FetchServices() {
    this.isLoadingServices = true;
    return new Promise((resolve, reject) => {
      this.API.GetBookingServices(this.clinicId).subscribe(
        (result) => {
          var res = result as BookingService[];
          this._unFiltered_Services = res;
          this.Services = deepCopy<BookingService[]>(this._unFiltered_Services);
          this.isLoadingServices = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public FetchStatuses() {
    this.isLoadingStatuses = true;
    return new Promise((resolve, reject) => {
      this.API.GetBookingStatuses().subscribe(
        (result) => {
          const res = result as GetBookingStatus[];
          this._unFiltered_Statuses = res;
          this.Statuses = deepCopy<GetBookingStatus[]>(
            this._unFiltered_Statuses
          );
          this.isLoadingStatuses = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public FetchDoctors() {
    this.isLoadingDoctors = true;
    return new Promise((resolve, reject) => {
      this.API.GetAllDoctors(this.clinicId).subscribe(
        (result) => {
          const res = result as Doctor[];
          this._unFiltered_Doctors = res;
          this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
          this.isLoadingDoctors = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public FetchPatients() {
    this.isLoadingPatients = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    return new Promise((resolve, reject) => {
      this.API.GetPatients(
        this.currentPage,
        this.pageSize,
        this.clinicId,
        this.searchTerm
      ).subscribe(
        (result) => {
          const res = result as PatientList;
          this.Patients = res.items;
          this.isLoadingPatients = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public FilterPatients(filter: string) {
    this.isLoadingPatients = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    return new Promise((resolve, reject) => {
      this.API.GetPatients(
        this.currentPage,
        this.pageSize,
        this.clinicId,
        filter
      ).subscribe(
        (result) => {
          const res = result as PatientList;
          this.Patients = res.items;
          this.isLoadingPatients = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public saveBooking() {
    this.isSubmitting = true;
    if (!this.booking.bookingID) {
      this.createBooking();
    } else {
      this.updateBooking();
    }
  }

  public createBooking() {
    this.booking.clinicId = this.clinicId;
    let dateAddMinute = this.addServiceDurationInMinutes(
      this.booking.bookingServiceId,
      this.booking.startDateTime
    );
    this.booking.startDateTime = moment
      .utc(this.booking.startDateTime, 'DD MM YYYY hh:mm:ss', true)
      .toDate();
    this.booking.endDateTime = dateAddMinute;
    this.API.CreateBooking(this.booking).subscribe(() => {
      this.loadData();
      this.isSubmitting = false;
      this.booking = {} as BookingModel;
      this._snackBar.open('¡Listo!, se ha creado la cita.', 'OK ', {
        duration: 10000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      });
      if (this.dataFromMatDialogEvent) {
        this.dialog.closeAll();
      }
    });
  }

  public updateBooking() {
    let dateAddMinute = this.addServiceDurationInMinutes(
      this.booking.bookingServiceId,
      this.booking.startDateTime
    );
    this.booking.startDateTime = moment(this.booking.startDateTime).toDate();
    this.booking.endDateTime = dateAddMinute;
    this.API.UpdateBooking(this.booking).subscribe(
      () => {
        this.booking = {} as BookingModel;
        this._snackBar.open('¡Listo!, se ha actualizado la cita.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.isSubmitting = false;
        if (this.dataFromMatDialogEvent) {
          this.dialog.closeAll();
        }
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL crear la cita', f);
      }
    );
  }

  public onServiceFilterStringChange(filter: string) {
    if (!filter) {
      this.Services = deepCopy<BookingService[]>(this._unFiltered_Services);
    }
    filter = filter.toLowerCase();
    const filteredServices = new Array<BookingService>();
    for (let i = 0; i < this._unFiltered_Services.length; i++) {
      const option = this._unFiltered_Services[i];
      if (option.bookingServiceName.toLowerCase().indexOf(filter) >= 0) {
        filteredServices.push(deepCopy<BookingService>(option));
      }
    }
    this.Services = filteredServices;
  }

  public onStatusFilterStringChange(filter: string) {
    if (!filter) {
      this.Statuses = deepCopy<GetBookingStatus[]>(this._unFiltered_Statuses);
    }
    filter = filter.toLowerCase();
    const filteredStatuses = new Array<GetBookingStatus>();
    for (let i = 0; i < this._unFiltered_Statuses.length; i++) {
      const option = this._unFiltered_Statuses[i];
      if (option.bookingStatusName.toLowerCase().indexOf(filter) >= 0) {
        filteredStatuses.push(deepCopy<GetBookingStatus>(option));
      }
    }
    this.Statuses = filteredStatuses;
  }

  public onDoctorFilterStringChange(filter: string) {
    if (!filter) {
      this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
    }
    filter = filter.toLowerCase();
    const filteredDoctors = new Array<Doctor>();
    for (let i = 0; i < this._unFiltered_Doctors.length; i++) {
      const option = this._unFiltered_Doctors[i];
      if (
        (option.doctorId + ' - ' + option.doctorName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredDoctors.push(deepCopy<Doctor>(option));
      }
    }
    this.Doctors = filteredDoctors;
  }

  public ResetBookingForm = () => {
    this.booking = {} as BookingModel;
  };
  addServiceDurationInMinutes(bookingServiceId: number, date: Date) {
    const service = this.Services.find((s) => {
      return s.bookingServiceId === this.booking.bookingServiceId;
    });

    this.booking.durationInMinutes = service.serviceDurationInMinutes;

    let newDate = moment(date)
      .add(service.serviceDurationInMinutes, 'minutes')
      .toDate();
    return newDate;
  }

  public playReminderSetAudios(remset: ReminderSetDto) {
    const links = remset.audios
      .filter((a) => a.messageOrPath.indexOf('http') > -1)
      .map((e) => e.messageOrPath);
    this.PlayAudioChain(links);
  }
  PlayAudioChain(file_names: string[]) {
    if (file_names.length) {
      this.isPlayingAudio = true;
      const arg = {
        src: file_names[0],
        onend: () => {
          file_names.shift();
          if (file_names.length > 0) {
            this.isPlayingAudio = true;
            this.PlayAudioChain(file_names);
          } else {
            this.isPlayingAudio = false;
          }
        },
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  StopAudio() {
    this.current_Audio_Hawl.stop();
    this.isPlayingAudio = false;
  }
  public onAppointmentDateChange(appointmentDate: Date) {
    this.API.GetReminderSchedulesConfig(
      this.clinicId,
      appointmentDate
    ).subscribe((result) => {
      this.getReminderSchedulesConfigResults = result;
      this.onDoctorSelected(this.booking.doctorId);
    });
  }
  public onDoctorSelected(doctorId: number) {
    this.ReminderSets = new Array<ReminderSetDto>();
    for (let r of this.getReminderSchedulesConfigResults) {
      if (r.doctorId == doctorId) {
        let newReminderSe = {} as ReminderSetDto;
        newReminderSe.reminderSetId = Number(r.reminderSetName.split('-')[0]);
        newReminderSe.reminderSetName = r.reminderSetName;
        this.ReminderSets.push(newReminderSe);
      }
    }
  }
}
