import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { PasswordChangeDTO, UserCreateDTO } from 'src/app/dtos/Users/Users';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from '../../api.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportChannelsDialogComponent } from '../../_dialogs/support-channels-dialog/support-channels-dialog.component';
import { TawkToService } from '../../_services/TawkToService';
import { User } from '../../_models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  currentUser: User;
  loading = false;
  submitted = false;
  returnUrl: string;
  password_strength: string;
  currentUserpassword: string;
  currentUserIdentifier: string;
  pswrdStrengthColor = 'Red';
  error: '';
  msjLogin: string;
  mustResetPassword = false;
  isPasswordValid = true;
  public NewUser = new UserCreateDTO();
  httpClient: HttpClient;
  isV2: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    public dialog: MatDialog,
    private tawkToService: TawkToService,
    private API: ApiService,
    private _snackBar: MatSnackBar
  ) {
    this.msjLogin = 'Iniciar Sesión';
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.httpClient = http;
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    this.isV2 = v2Flag;
  }
  public getAppLogo() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    if (v2Flag) {
      return '/images/v2-assets/citamed-logo-white.png';
    }
    return '/assets/img/whitelogo.png';
  }
  public getRootClass() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    if (v2Flag) {
      return { 'citamed-v2-cyan': true, 'login-container': true };
    }
    return { 'login-container': true };
  }

  public openSupportChannelsDialog() {
    this.dialog.open(SupportChannelsDialogComponent, {
      width: '60%',
      disableClose: true,
    });
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  moveToPasswordRecovery() {
    this.router.navigate(['/RecoverPassword']);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (r) => {
          if (r.mustResetPassword) {
            this.currentUserpassword = this.f.password.value;
            this.currentUserIdentifier = this.f.username.value;
            this.currentUser = this.authenticationService.currentUserValue;
            this.authenticationService.logout();
            this.mustResetPassword = true;
            this.submitted = false;
            this.error = '';
            this.loading = false;
            this.msjLogin =
              'Para continuar, es necesario que cambies tu contraseña';
            return;
          }

          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          this.error = error;
          this._snackBar.open(error, 'Entendido', {
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
          this.loading = false;
        }
      );
  }
  public CheckPasswordStrength(password) {
    if (!password) {
      this.isPasswordValid = false;
      this.password_strength = 'La contraseña es muy débil.';
      return;
    }
    this.isPasswordValid = false;
    this.password_strength = '';

    this.pswrdStrengthColor = '';
    if (password.length === 0) {
      return;
    }

    // Regular Expressions
    const regex = new Array();
    regex.push('[A-Z]'); // For Uppercase Alphabet
    regex.push('[a-z]'); // For Lowercase Alphabet
    regex.push('[0-9]'); // For Numeric Digits
    regex.push('[$@$!%*#?&]'); // For Special Characters

    let passed = 0;

    // Validation for each Regular Expression
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }

    // Validation for Length of Password
    if (passed > 2 && password.length > 8) {
      passed++;
    }

    // Display of Status
    let color = '';
    let passwordStrength = '';
    switch (passed) {
      case 0:
        break;
      case 1:
        passwordStrength = 'La contraseña es muy débil.';
        color = 'Red';
        this.isPasswordValid = false;
        break;
      case 2:
        passwordStrength = 'La contraseña es débil.';
        color = 'darkorange';
        this.isPasswordValid = true;
        break;
      case 3:
        break;
      case 4:
        passwordStrength = 'La contraseña es segura.';
        this.isPasswordValid = true;
        color = 'Green';
        break;
      case 5:
        this.isPasswordValid = true;
        passwordStrength = 'La contraseña es muy segura.';
        color = 'darkgreen';
        break;
    }
    this.password_strength = passwordStrength;
    this.pswrdStrengthColor = color;
  }

  onSubmitFPasswordChange() {
    this.submitted = true;
    this.loading = true;
    try {
      const passwordChange: PasswordChangeDTO = new PasswordChangeDTO();
      passwordChange.oldPassword = this.currentUserpassword;
      passwordChange.identifier = this.currentUserIdentifier;
      passwordChange.newPassword = this.NewUser.password;
      passwordChange.userID = this.currentUser.userId;
      passwordChange.clinicID = this.currentUser.clinicId;

      this.API.PasswordChange(passwordChange).subscribe(
        (result) => {
          this._snackBar.open(
            '¡Listo! Tu contraseña ha sido actualizada.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
          this.authenticationService.logout();
          this.mustResetPassword = false;
          this.msjLogin = 'iciar Sesión';
          this.loading = false;
          this.router.navigate([this.returnUrl]);
        },
        (e) => {
          this.onErrorOcurred(e);
          this.error = e;
        }
      );
    } catch (e) {
      this.onErrorOcurred(e);
    }
  }

  moveToLogin() {
    this.router.navigate(['/']);
  }
  private onErrorOcurred(error: any) {
    const snackBar = this._snackBar.open(
      '¡Oops! Ha ocurrido un error :(',
      'ok ',
      {
        duration: 8000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      }
    );

    this.submitted = false;
    this.loading = false;
    console.error(error);
  }

  // Keydown Events delete - backspace
  @HostListener('document:keydown.delete', ['$event'])
  onDeleteComponent(event: KeyboardEvent) {
    this.CheckPasswordStrength(this.NewUser.password);
  }
  @HostListener('document:keydown.backspace', ['$event'])
  onBackspaceComponent(event: KeyboardEvent) {
    this.CheckPasswordStrength(this.NewUser.password);
  }
}
