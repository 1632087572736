import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { AuthenticationService } from 'src/app/_services';
import { User } from 'src/app/_models/user';
import { AgendaComponent } from 'src/app/agenda/agenda.component';

// import { EventApi } from "@fullcalendar/core";

@Component({
  selector: 'app-bookings-agenda-page',
  templateUrl: './bookings-agenda-page.component.html',
  styleUrls: ['./bookings-agenda-page.component.scss'],
})
export class BookingsAgendaPageComponent implements OnInit {
  public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public accountId: number;
  @ViewChild(AgendaComponent) AgendaComponent: AgendaComponent;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
    this.accountId = this.User.clinicId;
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.accountId = this.ViewAs.clinicId;
    this.AgendaComponent.LoadListsEvents(this.accountId);
  }
}
