import * as moment from 'moment';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BookingModel, EventModel } from '../DTOS/Bookings/BookingDTO';
import { Calendar, CalendarApi, CalendarOptions } from '@fullcalendar/core';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../loading-dialog/loading-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services/authentication.service';
import { BookingAppointmentsList } from '../DTOS/Bookings/BookingAppointmentsDTO';
import { BookingsBookingFormComponent } from '../Pages/bookings-booking-form/bookings-booking-form.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { FullCalendarElement } from '@fullcalendar/web-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../_models';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import timeGreedPlugin from '@fullcalendar/timegrid';
import * as _ from 'lodash';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
})
export class AgendaComponent implements OnInit {
  public User: User;
  listEvents: BookingAppointmentsList[];
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  showCloseIcon: Boolean = false;
  date = new Date();
  from: Date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  to: Date = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  public pageSize = 100;
  public currentPage = 1;
  public totalSize = 0;
  Events = [];
  EventTarget: EventTarget;
  EventModel: EventModel = null;
  BookingEventsModels: EventModel[] = [];
  booking: BookingModel;
  @Input() public accountID: number;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @ViewChild('calendar') calendarRef: ElementRef<FullCalendarElement>;
  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  sidepanelBooking: BookingModel = {} as any;

  public openAddNewSidePanel(startDateTime: string, endDateTime?: string) {
    this.sidepanelBooking = {} as BookingModel;
    this.sidepanelBooking.startDateTimeUTC = moment.utc(startDateTime).toDate();
    this.sidepanelBooking.endDateTime =
      _.isEmpty(endDateTime) == true ? null : moment.utc(endDateTime).toDate();
    this.sidepanelBooking.clinicId = this.accountID;
    this.dialog
      .open(BookingsBookingFormComponent, {
        maxWidth: '37.5rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
        data: this.sidepanelBooking,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        this.LoadListsEvents(this.accountID);
      });
  }

  public openAddNewEditSidePanel(booking: BookingModel) {
    this.showCloseIcon = false;
    this.sidepanelBooking = booking;
    this.slidePanelOpen = true;
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelBooking = {} as BookingModel;
    this.LoadListsEvents(this.accountID);
  }

  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {
    const name = Calendar.name; // add this line in your constructor
  }
  calendarOptions: CalendarOptions;
  Calendar: Calendar;
  CalendarApi: CalendarApi;

  ngOnInit() {
    this.User = this.authenticationService.currentUserValue;
    this.accountID = this.User.clinicId;
    this.LoadListsEvents(this.accountID);
  }

  SetCaledarEvents(sourcesEvent) {
    this.calendarOptions = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      },
      select: (selectionInfo) => {
        this.openAddNewSidePanel(selectionInfo.startStr, selectionInfo.endStr);
      },
      eventClick: (info) => {
        this.GetBooking(Number(info.event.id));
      },
      eventDrop: (info) => {
        let updateEvent = this.mapEvent(info);
        this.GetBookingUpdateDate(
          updateEvent,
          Number(info.event.id),
          info.oldEvent.start
        );
      },
      eventResize: (info) => {
        let updateEvent = this.mapEvent(info);
        this.GetBookingUpdateDate(
          updateEvent,
          Number(info.event.id),
          info.oldEvent.start
        );
      },
      events: (info, successCallback, failureCallback) => {
        let startInfo: String = moment.utc(info.start).format();
        let fromInfo: String = moment.utc(this.from).format();
        if (startInfo === fromInfo) {
          successCallback(sourcesEvent);
        } else {
          this.from = moment.utc(info.startStr).toDate();
          this.to = moment.utc(info.endStr).toDate();
          this.LoadListsEvents(this.accountID);
          successCallback(sourcesEvent);
        }
      },
      initialView: 'dayGridMonth',
      weekends: false,
      editable: true,
      dayHeaders: true,
      defaultAllDay: false,
      eventResizableFromStart: true,
      droppable: true,
      displayEventTime: true,
      displayEventEnd: true,
      locale: esLocale,
      plugins: [dayGridPlugin, timeGreedPlugin, interactionPlugin],
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      nowIndicator: true,
      timeZone: 'local',
      businessHours: [
        {
          daysOfWeek: [1, 2, 3, 4, 5, 6],
          startTime: '05:00',
          endTime: '22:00',
        },
      ],
      eventTimeFormat: {
        // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: true,
      },
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      },
      viewClassNames: (arg) => {
        return 'cmed-agenda-container';
      },
    };
  }

  public async LoadListsEvents(accountID) {
    this.BookingEventsModels = [];
    this.isSubmitting = true;
    this.isLoading = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetBookingAppointments(
      accountID,
      this.currentPage,
      this.pageSize,
      null,
      this.from,
      this.to
    ).subscribe(
      (result) => {
        const { items } = result as BookingAppointmentsList;
        items.forEach((event) => {
          let end = event.endDateTime.toLocaleString();
          let start = event.startDateTime.toLocaleString();
          this.EventModel = {
            id: event.bookingID.toString(),
            start: start,
            end: end,
            title: `${event.patient.firstName}  ${event.patient.firstLastName} (${event.bookingServiceName})`,
            backgroundColor: event.bookingStatusColor,
          };
          this.BookingEventsModels.push(this.EventModel);
        });
        this.SetCaledarEvents(this.BookingEventsModels);
        this.isSubmitting = false;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public async GetBooking(BookingID: number) {
    let clinicID = this.accountID ? this.accountID : this.User.clinicId;
    this.ShowLoading();
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetBooking(clinicID, BookingID).subscribe(
      (result) => {
        this.hideLoading();
        result.startDateTimeUTC = result.startDateTime;
        result.endDateTimeUTC = result.endDateTime;
        this.openAddNewEditSidePanel(result);
      },
      (error) => {
        console.error(error);
        this.hideLoading();
      }
    );
  }

  UpdateEventDrop(Booking: BookingModel) {
    this.API.UpdateBooking(Booking).subscribe(
      () => {
        this._snackBar.open('¡Listo!, se ha actualizado la cita.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.LoadListsEvents(this.accountID);
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL algualizar la cita', f);
      }
    );
  }

  public async GetBookingUpdateDate(
    Event: EventModel,
    BookingID: number,
    oldStartEvent
  ) {
    let data = {
      title: `Actualización Cita`,
      htmlContent: this.formatStringChangeDate(Event, oldStartEvent),
      yesBtnLabel: 'Continuar',
      noBtnLabel: 'Cancelar',
    };
    this.dialog
      .open(ConfirmDialogComponent, {
        data: data,
        width: '31.25rem',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          let clinicID = this.accountID ? this.accountID : this.User.clinicId;
          this.ShowLoading();
          this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
          this.API.GetBooking(clinicID, BookingID).subscribe(
            (result) => {
              this.hideLoading();
              this.booking = result;
              let endDateTime = new Date(Event.end);
              let startDateTime = new Date(Event.start);
              this.booking.endDateTimeUTC = endDateTime;
              this.booking.startDateTimeUTC = startDateTime;
              this.booking.endDateTime = endDateTime;
              this.booking.startDateTime = startDateTime;
              this.UpdateEventDrop(this.booking);
            },
            (error) => {
              console.error(error);
              this.hideLoading();
            }
          );
        } else {
          this.dialog.closeAll();
          this.LoadListsEvents(this.accountID);
        }
      });
  }

  ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '31.25rem',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  mapEvent(info) {
    let { _def } = info.oldEvent;
    let { _instance } = info.event;
    let { range } = _instance;
    let end = range.end.toString();
    let start = range.start.toString();
    let updateEvent: EventModel = {
      title: _def.title,
      id: info.event.id,
      start: start,
      end: end,
      color: '',
      description: '',
      backgroundColor: '',
    };
    return updateEvent;
  }
  reloadPage() {
    window.location.reload();
  }
  formatStringChangeDate(Event: EventModel, oldStartDate: Date) {
    let message = '';
    if (
      moment(Event.start).format('YYYY-MM-DD') ==
      moment(oldStartDate).format('YYYY-MM-DD')
    ) {
      message = `¿Desea cambiar la hora de la cita de <strong> ${
        Event.title
      }</strong>, de las <strong>${moment
        .utc(oldStartDate)
        .format('LT')}</strong>  a las  <strong> ${moment
        .utc(Event.start)
        .format('LT')}</strong> ?`;
    } else {
      message = `¿Desea cambiar la fecha de la cita de <strong>${
        Event.title
      }</strong>, de <strong> ${moment
        .utc(oldStartDate)
        .locale('es')
        .format('LLLL')}</strong>  al <strong> ${moment
        .utc(Event.start)
        .locale('es')
        .format('LLLL')} </strong>?`;
    }
    return message;
  }
}
