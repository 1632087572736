export interface WhiteListedPhoneNumbers {
  whiteListedPhoneNumberId: number;
  clinicID?: number;
  companyID?: number;
  phoneNumber: string;
  companyName: string;
  clinicName: string;
  deleteDate?: Date;
  createDate?: Date;
  username: string;
}

export interface WhiteListedPhoneNumbersList {
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  items: WhiteListedPhoneNumbers[];
}

export class WhiteListedPhoneNumbers {
  whiteListedPhoneNumberId: number;
  clinicID?: number;
  companyID?: number;
  phoneNumber: string;
  companyName: string;
  clinicName: string;
  deleteDate?: Date;
  createDate?: Date;
  username: string;
}
